import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    "sendTestEmailModal",
    "subjectLine",
    "bodyContent",
    "testRecipientEmails",
    "testEmailSucceededAlert",
    "testEmailSucceededMessage",
    "testEmailFailedAlert",
    "testEmailFailedMessage",
    "sendTestBtn"
  ]

  initialize(){
    this.appUid = this.data.get('appUid')
    this.isTestEmailSending = false
    this.orignalTmMainContentHolderZIndex = document.getElementById('tmMainContentHolder').style.getPropertyValue('z-index')
  }

  connect(){}

  disconnect(){}

  openSendTestEmailModal(){
    // hack to not show topNav above/on the modal background
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
    this.sendTestEmailModalTarget.style.display = "block"
  }

  closeSendTestEmailModal(){
    this.hideTestEmailFailedAlert()
    this.hideTestEmailSucceededAlert()
    this.sendTestEmailModalTarget.style.display = "none"
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.orignalTmMainContentHolderZIndex)
  }

  hideTestEmailSucceededAlert(){
    this.testEmailSucceededAlertTarget.style.display = "none"
  }

  hideTestEmailFailedAlert(){
    this.testEmailFailedAlertTarget.style.display = "none"
  }

  showTestEmailSucceededAlert(message){
    this.hideTestEmailFailedAlert()
    this.testEmailSucceededMessageTarget.innerHTML = message
    this.testEmailSucceededAlertTarget.style.display = "block"
  }

  showTestEmailFailedAlert(message){
    this.hideTestEmailSucceededAlert()
    this.testEmailFailedMessageTarget.innerHTML = message
    this.testEmailFailedAlertTarget.style.display = "block"
  }

  sendTest(){
    if (this.subjectLineTarget.value === "" || this.subjectLineTarget.value == undefined){
      this.showTestEmailFailedAlert("Please provide subject for the email")
    } else if (this.isTestEmailSending == false) {
      this.isTestEmailSending = true
      this.sendTestBtnTarget.innerHTML = "Sending..."
      this.hideTestEmailSucceededAlert()
      this.hideTestEmailFailedAlert()

      $.ajax({
        type: "POST",
        url: '/api/web/admin/marketing/emails/send_test',
        data: {
          "subject_line": this.subjectLineTarget.value,
          "body_content": this.bodyContentTarget.value,
          "test_recipient_emails": this.testRecipientEmailsTarget.value
        },
        success: function(data) {
          this.isTestEmailSending = false
          this.sendTestBtnTarget.innerHTML = "Send"
          this.showTestEmailSucceededAlert(data.message)
        }.bind(this),
        error: function(data) {
          this.isTestEmailSending = false
          this.sendTestBtnTarget.innerHTML = "Send"
          this.showTestEmailFailedAlert(data.responseJSON.error_message)
        }.bind(this)
      })
    }
  }
}
