import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ ]

  initialize() {
    console.log("settings--advanced--roles--edit-permissions controller initialize")
  }

  connect() {
    console.log("settings--advanced--roles--edit-permissions controller connected");
    this.groupIds = JSON.parse(this.data.get("groupIds"))
    this._update_permission_group_checkboxes_if_required()

  }

  disconnect(){
    console.log("settings--advanced--roles--edit-permissions controller disconnect")
  }

  expandAllPermissionGroups(){
    console.log("settings--advanced--roles--edit-permissions controller: expanding groups : " )
    $(".permission-table").removeClass('hidden');

    $(`.btn-expand-group`).addClass('hidden')
    $(`.btn-collapse-group`).removeClass('hidden')
  }

  collapseAllPermissionGroups(){
    console.log("settings--advanced--roles--edit-permissions controller: expanding groups : " )
    $(".permission-table").addClass('hidden');
    $(`.btn-collapse-group`).addClass('hidden')
    $(`.btn-expand-group`).removeClass('hidden')
  }

  collapseGroup(e){
    let groupId = e.target.dataset.groupId
    $(`.permission-table[data-group-id='${groupId}']`).addClass('hidden')
    $(`.btn-collapse-group[data-group-id='${groupId}']`).addClass('hidden')
    $(`.btn-expand-group[data-group-id='${groupId}']`).removeClass('hidden')
  }

  expandGroup(e){
    let groupId = e.target.dataset.groupId
    $(`.permission-table[data-group-id='${groupId}']`).removeClass('hidden')
    $(`.btn-expand-group[data-group-id='${groupId}']`).addClass('hidden')
    $(`.btn-collapse-group[data-group-id='${groupId}']`).removeClass('hidden')
  }

  selectAllPermissionsOfGroup(e){
    let groupId = e.target.dataset.groupId
    $(`.permission-checkbox:input[type=checkbox][data-group-id='${groupId}']:not(:checked)`).click()

    $(`.permission-group-checkbox--empty[data-group-id='${groupId}']`).addClass('hidden')
    $(`.permission-group-checkbox--dashed[data-group-id='${groupId}']`).addClass('hidden')
    $(`.permission-group-checkbox--checked[data-group-id='${groupId}']`).removeClass('hidden')
  }

  unselectAllPermissionsOfGroup(e){
    let groupId = e.target.dataset.groupId
    $(`.permission-checkbox:input[type=checkbox][data-group-id='${groupId}']:checked`).click()

    $(`.permission-group-checkbox--checked[data-group-id='${groupId}']`).addClass('hidden')
    $(`.permission-group-checkbox--dashed[data-group-id='${groupId}']`).addClass('hidden')
    $(`.permission-group-checkbox--empty[data-group-id='${groupId}']`).removeClass('hidden')
  }

  permissionCheckboxChanged(e){
    let groupId = e.target.dataset.groupId
    this._update_permission_group_checkbox_for_group(groupId)
  }

  _update_permission_group_checkboxes_if_required(){
    for (const groupId of this.groupIds) {
      this._update_permission_group_checkbox_for_group(groupId)
    }
  }

  _update_permission_group_checkbox_for_group(groupId){
    // Show permission-group-checkbox--empty if zero permissions are selected
    // Show permission-group-checkbox--checked if all permissions (of group) are selected
    // Show permission-group-checkbox--dashed if some permissions are selected but not all

    if(this._is_all_permissions_selected_for_group(groupId)){
      // All permissions are selected
      $(`.permission-group-checkbox--dashed[data-group-id='${groupId}']`).addClass('hidden')
      $(`.permission-group-checkbox--empty[data-group-id='${groupId}']`).addClass('hidden')
      $(`.permission-group-checkbox--checked[data-group-id='${groupId}']`).removeClass('hidden')
    } else if(this._selected_permissions_count_for_group(groupId) == 0) {
      // Zero permissions are selected
      $(`.permission-group-checkbox--dashed[data-group-id='${groupId}']`).addClass('hidden')
      $(`.permission-group-checkbox--checked[data-group-id='${groupId}']`).addClass('hidden')
      $(`.permission-group-checkbox--empty[data-group-id='${groupId}']`).removeClass('hidden')
    } else {
      // Some permissions are selected
      $(`.permission-group-checkbox--checked[data-group-id='${groupId}']`).addClass('hidden')
      $(`.permission-group-checkbox--empty[data-group-id='${groupId}']`).addClass('hidden')
      $(`.permission-group-checkbox--dashed[data-group-id='${groupId}']`).removeClass('hidden')
    }
  }

  _total_permissions_count_for_group(groupId){
    return $(`.permission-checkbox:input[type=checkbox][data-group-id='${groupId}']`).length
  }

  _selected_permissions_count_for_group(groupId){
    return $(`.permission-checkbox:input[type=checkbox][data-group-id='${groupId}']:checked`).length
  }

  _is_all_permissions_selected_for_group(groupId){
    if(this._total_permissions_count_for_group(groupId) == this._selected_permissions_count_for_group(groupId)){
      return true
    } else {
      return false
    }
  }
}
