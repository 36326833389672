import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "paymentGateway",
    "paytm",
    "instamojo",
    "razorpay",
    "stripe",
    "twocheckout",
    "xendit",
    "paystack"
  ]

  initialize() {
    this.orignalTmMainContentHolderZIndex = document.getElementById('tmMainContentHolder').style.getPropertyValue('z-index')
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.orignalTmMainContentHolderZIndex)
  }

  configurePaymentgateway(event) {
    var selectedPaymentGateway = event.target.dataset.selectedPaymentGateway

    this.instamojoTarget.classList.toggle('hidden', true)
    this.paytmTarget.classList.toggle('hidden', true)
    this.twocheckoutTarget.classList.toggle('hidden', true)
    this.razorpayTarget.classList.toggle('hidden', true)
    this.stripeTarget.classList.toggle('hidden', true)
    this.xenditTarget.classList.toggle('hidden', true)
    this.paystackTarget.classList.toggle('hidden', true)

    this.paymentGatewayTargets.forEach((target, index) => {
      let paymentGateway = target.dataset.selectedPaymentGateway

      if(paymentGateway == selectedPaymentGateway) {
        target.classList.add("border-2", "border-primary-500")
      } else {
        target.classList.remove("border-2", "border-primary-500");
      }

    });

    if(selectedPaymentGateway == 'instamojo') {
      this.instamojoTarget.classList.toggle('hidden', false)
    } else if(selectedPaymentGateway == 'paytm') {
      this.paytmTarget.classList.toggle('hidden', false)
    } else if(selectedPaymentGateway == 'stripe') {
      this.stripeTarget.classList.toggle('hidden', false)
    } else if(selectedPaymentGateway == 'twocheckout') {
      this.twocheckoutTarget.classList.toggle('hidden', false)
    } else if(selectedPaymentGateway == 'razorpay') {
      this.razorpayTarget.classList.toggle('hidden', false)
    } else if(selectedPaymentGateway == 'xendit') {
      this.xenditTarget.classList.toggle('hidden', false)
    } else if(selectedPaymentGateway == 'paystack') {
      this.paystackTarget.classList.toggle('hidden', false)
    }
  }
}
