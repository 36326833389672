import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["card","badge","title", "status", "time", "link"]

  initialize(){}

  connect(){
    fetch(this.data.get('url'))
      .then((res) => res.text())
      .then((html) => {
        this.cardTarget.classList.remove('animated-background');
        this.cardTarget.innerHTML=html;
      });
  }

  disconnect(){
  }
}
