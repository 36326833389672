import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

// https://stackoverflow.com/questions/44932576/jquery-ui-sortable-is-not-a-function-with-webpack
require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');

export default class extends Controller {
  static targets = [ "target1" ]

  initialize(){
    this.appUid = this.data.get('appUid');
    this.productId = this.data.get('productId');
    this.productType = this.data.get('productType')
    this._makeSortable()
  }

  connect(){}

  disconnect(){}

  _makeSortable(){
    $(this.element).sortable({
      update: function(e, ui){
        this._requestToMoveListItem($(this.element).sortable("toArray",{attribute: "product-variant-id"}))
      }.bind(this)
    })
  }

  _requestToMoveListItem(newProductVariantOrderIds){
    $.ajax({
      type: "PUT",
      url: `/api/web/admin/${this.productType}_manager/${this.productId}/pricing/sort-position`,
      data: {
        "new_product_variants_sort_order": newProductVariantOrderIds
      },
      success: function(data) {
        console.log(data)
      }.bind(this),
      error: function(data) {
        console.log("Error for sorting product variants.")
      }.bind(this)
    })
  }

}
