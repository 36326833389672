import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []
  initialize() {}

  connect() {
    this.courseId = null
    $("#duplicate-course-confirm-modal-button").click(function() {
      this.initiateCourseDuplication(event);
    }.bind(this));

    $("#duplicate-course-close-modal-button").click(function() {
      this.closeDuplicateCourseConfirmationModal();
    }.bind(this));
  }

  disconnect(){}

  toggleCourseFeatured(event) {
    const element = event.target
    let courseId = element.value

    var fd = new FormData();
    fd.append("featured", element.checked);

    Rails.ajax({
      type: "PUT",
      url: `/admin/content/courses/${courseId}/set-featured`,
      data: fd,
      success: function(data) {}.bind(this),
      error: function(data) {
        element.checked = !element.checked
        alert("Failed to set featured")
      }.bind(this)
    })
  }

  closeDuplicateCourseConfirmationModal() {
    $(".course-duplication-confirmation-modal").addClass("hidden")
  }

  openDuplicateCourseConfirmationModal(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    this.courseId = event.currentTarget.dataset.currentCourseId

    $(".course-duplication-confirmation-modal").removeClass("hidden")
  }

  initiateCourseDuplication(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    this.showLoading()

    Rails.ajax({
      type: "POST",
      url: `/admin/content/courses/${this.courseId}/initiate-duplication`,
      data: {},
      success: function(data) {
        console.log("Duplicating course completed");
        this.closeDuplicateCourseConfirmationModal()
        this.hideLoading()
      }.bind(this),
      error: function(data) {
        console.log("Failed to duplicate course")
        this.closeDuplicateCourseConfirmationModal()
        this.hideLoading()
      }.bind(this)
    })
  }

  showLoading() {
    $("#duplicate-course-confirm-modal-button").addClass("hidden")
    $("#duplicate-course-confirm-loading-symbol").removeClass("hidden")
  }

  hideLoading() {
    $("#duplicate-course-confirm-modal-button").removeClass("hidden")
    $("#duplicate-course-confirm-loading-symbol").addClass("hidden")
  }

  duplicatingCourseInProgressAlert(event) {
    event.preventDefault()
    $("#course-cloning-in-progress-modal").removeClass("hidden");
  }
}
