import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = [
    "courseName",
    "createCourseBtn"
  ];

  initialize(){}

  toggleCreateCourseVisibility() {
    if(this.courseNameTarget != undefined && this.courseNameTarget.value != "") {
      this._enableCreateAccountButton()
    } else {
      this._disableCreateAccountButton()
    }
  }

  _disableCreateAccountButton() {
    this.createCourseBtnTarget.disabled = true
    this.createCourseBtnTarget.classList.add("cursor-not-allowed")
    this.createCourseBtnTarget.setAttribute("style", "background-color: #dbe2ea;");
    this.createCourseBtnTarget.classList.remove("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  _enableCreateAccountButton() {
    this.createCourseBtnTarget.disabled = false
    this.createCourseBtnTarget.classList.remove("cursor-not-allowed")
    this.createCourseBtnTarget.removeAttribute("style")
    this.createCourseBtnTarget.classList.add("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  trackFbGtmEventsForCreateCourse() {
    FbGtmTracker.trackOnboardingEvent('TMO_ONBOARDING_CREATE_COURSE_NEXT_CLICKED', {});
  }

  trackFbGtmEventsForSkipCreateCourse() {
    FbGtmTracker.trackOnboardingEvent('TMO_ONBOARDING_CREATE_COURSE_SKIP_CLICKED', {});
  }
}
