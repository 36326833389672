import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    "newFaqDiv",
    "faqs"
  ]

  initialize(){

  }

  connect(){
    this.createNewFaqSection()
  }

  disconnect(){}

  createNewFaqSection(){
    $.ajax({
      type: "GET",
      url: '/api/web/admin/marketing/referrals/referral_faqs/new.js',
      success: function(data) {
        console.log(JSON.parse(data))
        this.newFaqDivTarget.insertAdjacentHTML('beforeend', JSON.parse(data));
        this.count = this.faqsTargets.length
        this.faqsTargets[this.count-1].firstElementChild.setAttribute('name', "referral_config[referral_faqs_attributes["+this.count+ "][question]]");
        this.faqsTargets[this.count-1].children[1].setAttribute('name', "referral_config[referral_faqs_attributes["+this.count+ "][answer_text]]");
        this.faqsTargets[this.count-1].lastElementChild.setAttribute('id', "referral_config[referral_faqs_attributes["+this.count+ "][answer_text]]");
      }.bind(this),
      error: function(data) {
        console.log(data)
      }.bind(this)
    })
  }

  removeFaq(event){
    this.destroyInput = event.currentTarget.parentElement.querySelector(".destroy-input")
    if(this.destroyInput != null){
      this.destroyInput.setAttribute('value', '1')
      event.currentTarget.parentNode.classList.add('hidden')
    }
    else{
      event.currentTarget.parentNode.remove()
    }
  }

}
