import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['thumbnailImage', 'thumbnailImageLoadingIndicator']

  connect(){
    this.loadingIndicatorTarget
    this._set_thumbnail_image_url_for_missing_thumbnail_images()
  }

  _set_thumbnail_image_url_for_missing_thumbnail_images(){
    this.thumbnailImageTargets.forEach((thumbnailImageElement, index) => {
      if(thumbnailImageElement.dataset.thumbnailImageUrl == "" || thumbnailImageElement.dataset.thumbnailImageUrl == undefined || thumbnailImageElement.dataset.thumbnailImageUrl == null){
        this._fetch_or_generate_thumbnail_image_url(thumbnailImageElement.dataset.certificatesDesignId)
      }
    })
  }

  _fetch_or_generate_thumbnail_image_url(certificatesDesignId){
    $.ajax({
      url: `/api/web/admin/certificates/designs/${certificatesDesignId}/fetch-or-generate-thumbnail-image-url.json`,
      method: "GET",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data) {
        $(`img[data-content--certificates--design-thumbnail-image-target='thumbnailImage'][data-certificates-design-id='${certificatesDesignId}']`).attr("src", data.thumbnail_image_url)
        $(`div[data-content--certificates--design-thumbnail-image-target='thumbnailImageLoadingIndicator'][data-certificates-design-id='${certificatesDesignId}']`).addClass('hidden')
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }
}
