import { data } from 'jquery'
import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = [
    "calendlyContainer",
    "demoRegistrationCalendlyInline"
  ]

  initialize() {
    window.addEventListener("message", this.calendlyEventScheduled.bind(this));
    this.open()
  }

  open() {
    this.loadCalendly()
  }

  close() {
    this._trackDemoWebinarModalClosed()
    this.calendlyContainerTarget.removeAttribute("style");
    this.calendlyContainerTarget.classList.remove("show");
    this.calendlyContainerTarget.classList.add("hidden");
  }

  loadCalendly() {
    Calendly.initInlineWidget({
      url: this.data.get("calendlyUrl"),
      parentElement: this.demoRegistrationCalendlyInlineTarget,
      prefill: {
        name: this.data.get("prefillName"),
        email: this.data.get("prefillEmail")
      }
    });
  }

  calendlyEventScheduled(e) {
    if (this.isCalendlyEvent(e)) {
      if (e.data.event == "calendly.event_scheduled") {
        $.ajax({
          type: 'post',
          url: '/api/web/admin/tmo_webinar_app_user_registrations/register?src=modal',
          data: e.data.payload,
          success: function(response) {
            console.log("Calendly event registered");
            var parsedResult = eval(response);
            FbGtmTracker.trackAdminDashboardEvent('TMO_DEMO_BOOKING_FINISHED', parsedResult['event_details']);

            $(".sideNavDemoWebinarRegistered").each(function( index, element ) {
              $(element).removeClass("hidden")
              $(element).html(parsedResult['tmo_webinar_details_html'])
            });

            $(".sideNavDemoWebinarNotRegistered").each(function( index, element ) {
              $(element).addClass("hidden")
            });
          }.bind(this)
        })
      }
    }
  }

  isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  };

  _trackDemoWebinarModalClosed() {
    $.ajax({
      url: '/api/web/admin/tmo_webinar_app_user_registrations/modal_closed',
      method: "post",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data) {
        console.log("event logged");
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }
}
