import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static get targets() {
    return [
      "openReplyBoxButton",
      "commentBox",
      "replyAsAcademyRadioButton",
      "inputBox",
      "repliesSection",
      "replyButtonInModal",
      "replyAsAcademyRadioButtonInModal",
      "inputBoxInModal"
    ]
  }

  initialize(){
    this.commentId = this.data.get('commentId');
  }

  connect(){
  }

  disconnect(){}

  reply() {
    if(this.replyAsAcademyRadioButtonTarget.checked) {
      TmoEvents.logAsync("TMO_COMMENT_REPLY_SUBMITTED_ACADEMY", {})
    } else {
      TmoEvents.logAsync("TMO_COMMENT_REPLY_SUBMITTED_MYSELF", {})
    }

    var fd = new FormData();
    fd.append("reply_content", this.inputBoxTarget.value);
    fd.append("as_academy", this.replyAsAcademyRadioButtonTarget.checked);
    Rails.ajax({
      type: "POST",
      url: `/api/web/admin/comments/${this.commentId}/reply`,
      data: fd,
      success: function(data) {
        this.addReplyToRepliesSection();
        this.closeReplyInput();
        this.inputBoxTarget.value = "";
      }.bind(this),
      error: function(data) {
        alert("Failed to reply to comment")
      }.bind(this)
    })
  }

  closeReplyInput() {
    this.openReplyBoxButtonTarget.classList.add("sm:inline-flex");
    this.commentBoxTarget.classList.add("hidden");
  }

  openReplyInput() {
    TmoEvents.logAsync("TMO_COMMENT_REPLY_INITIATED", {})

    this.openReplyBoxButtonTarget.classList.remove("sm:inline-flex");
    this.commentBoxTarget.classList.remove("hidden");
  }

  addReplyToRepliesSection() {
    var replyBox = document.createElement("div");
    replyBox.classList.add("ml-5", "bg-white", "p-3", "rounded");

    var reply = document.createElement("div");
    reply.classList.add("bg-white");

    var slug = document.createElement("div");
    slug.classList.add("text-xs", "mb-2")

    var repliedAs = this.replyAsAcademyRadioButtonTarget.checked ? "an academy" : "an admin";
    var today = new Date(Date.now());
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    slug.textContent = `You replied as ${ repliedAs } on ${today.toLocaleDateString('en-US', options)} `

    reply.textContent = this.inputBoxTarget.value;

    replyBox.appendChild(slug);
    replyBox.appendChild(reply);
    this.repliesSectionTarget.appendChild(replyBox);

    if(this.repliesSectionTarget.classList.contains("hidden")) {
      this.repliesSectionTarget.classList.remove("hidden")
    }
  }

  replyInModal() {
    if(this.replyAsAcademyRadioButtonTarget.checked) {
      TmoEvents.logAsync("TMO_COMMENT_REPLY_SUBMITTED_ACADEMY", {})
    } else {
      TmoEvents.logAsync("TMO_COMMENT_REPLY_SUBMITTED_MYSELF", {})
    }
    var fd = new FormData();
    fd.append("reply_content", this.inputBoxInModalTarget.value);
    fd.append("as_academy", this.replyAsAcademyRadioButtonInModalTarget.checked);
    Rails.ajax({
      type: "POST",
      url: `/api/web/admin/comments/${this.commentId}/reply`,
      data: fd,
      success: function(data) {
        this.addReplyToRepliesSectionInModal();
        this.inputBoxInModalTarget.value = "";

      }.bind(this),
      error: function(data) {
        alert("Failed to reply to comment")
      }.bind(this)
    })
  }

  addReplyToRepliesSectionInModal() {
    var replyBox = document.createElement("div");
    var reply = document.createElement("div");
    var slug = document.createElement("div");
    var repliedAs = this.replyAsAcademyRadioButtonInModalTarget.checked ? "as academy" : "as admin";
    var today = new Date(Date.now());
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    slug.textContent = `You replied as ${ repliedAs } on ${today.toLocaleDateString('en-US', options)} `


    reply.textContent = this.inputBoxInModalTarget.value;
    slug.classList.add("text-xs", "mb-2")
    replyBox.classList.add("ml-5", "bg-white", "p-2");
    reply.classList.add("bg-white");
    replyBox.appendChild(slug);
    replyBox.appendChild(reply);
    this.repliesSectionTarget.appendChild(replyBox);
    if(this.repliesSectionTarget.classList.contains("hidden")) {
      this.repliesSectionTarget.classList.remove("hidden")
    }
  }
}
