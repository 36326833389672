import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import { unsubscribeTokens } from 'shared/utils/utils'

export default class extends Controller{
  static targets = ["title", "body", "footer"]
  initialize(){
    this.subscriptions = []
  }

  connect(){
    this.subscriptions.push(PubSub.subscribe(EVENTS.PAGE_MAKER_MODAL.SHOW, this.show.bind(this)))
    this.subscriptions.push(PubSub.subscribe(EVENTS.PAGE_MAKER_MODAL.HIDE, this.hide.bind(this)))
  }

  disconnect(){
    unsubscribeTokens(this.subscriptions)
  }

  show(msg, modal){
    this._setTitle(modal.title)
    this._showModal()
    this._loadData(modal.data_url)
  }

  hide(msg, modal){
    $(this.element).modal('hide')
  }

  //There is no Private method support by default in javascript,
  //there are workaround but for our case its sufficient to follow
  //a convetion of beginning method names with _ to denote that
  //they should not be called from outside the class

  _setTitle(title){
    this.titleTarget.innerHTML = title
  }

  _showModal(){
    $(this.element).modal()
  }

  _loadData(url){
    var csrf_token = $('meta[name="csrf-token"]').attr('content')
    fetch(url,{
      headers: {
        'Content-Type' : 'text/javascript',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf_token
      },
      credentials: 'same-origin'
    })
    .then(response => response.text())
    .then(js_response => {
      this._renderBody(eval(js_response))
    })
  }

  _renderBody(content){
    this.bodyTarget.innerHTML = content
  }
}
