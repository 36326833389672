import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ "hiddenLabel", "hideButton", "unhideButton" ]
  }

  initialize(){
    this.appUid = this.data.get('appUid');
    this.courseRatingId = this.data.get('courseRatingId');
    this.saving = false;
  }

  connect(){
    this.updateUI();
  }

  disconnect(){}

  updateUI() {
    if (this.data.get("isHidden") == "true") {
      this.hideButtonTarget.classList.add("hidden");
      this.hiddenLabelTarget.classList.remove("hidden");
      this.unhideButtonTarget.classList.remove("hidden");
    } else {
      this.hideButtonTarget.classList.remove("hidden");
      this.hiddenLabelTarget.classList.add("hidden");
      this.unhideButtonTarget.classList.add("hidden");
    }

    if (this.saving == true) {
      this.hideButtonTarget.innerHTML = "Hiding...";
      this.unhideButtonTarget.innerHTML = "Unhiding...";
    } else {
      this.hideButtonTarget.innerHTML = "Hide";
      this.unhideButtonTarget.innerHTML = "Unhide";
    }
  }

  hideRating() {
    if (this.saving == true) {
      return;
    }

    this.saving = true;
    this.updateUI();

    Rails.ajax({
      type: "PUT",
      url: `/api/web/admin/course_ratings/${this.courseRatingId}/mark-as-hidden`,
      data: {},
      success: function(data) {
        this.saving = false;
        this.data.set("isHidden", "true");
        this.updateUI();
      }.bind(this),
      error: function(data) {
        this.saving = false;
        this.updateUI();
        alert("Failed to hide rating")
      }.bind(this)
    })
  }

  unhideRating() {
    if (this.saving == true) {
      return;
    }

    this.saving = true;
    this.updateUI();

    Rails.ajax({
      type: "PUT",
      url: `/api/web/admin/course_ratings/${this.courseRatingId}/mark-as-unhidden`,
      data: {},
      success: function(data) {
        this.saving = false;
        this.data.set("isHidden", "false");
        this.updateUI();
      }.bind(this),
      error: function(data) {
        this.saving = false;
        this.updateUI();
        alert("Failed to unhide rating")
      }.bind(this)
    })
  }

}
