import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = [
    "employeeEmailInput",
    "employeeFirstNameInput",
    "employeeLastNameInput",
    "employeeIdInput",
    "fillUpDetailsIcon",
    "allDetailsAddedIcon",
    "callingCodeInput",
    "employeeMobileNumberInput",
    "jobDesignationInput",
    "baseLocationInput",
    "employeeJoiningDateInput",
    "assignTeamInput",
    "assignBatchInput",
    "copyInviteLinkText",
    "copyInviteLinkBtn",
    "moreCoursesBtn",
    "moreCoursesContainer",
    "moreBatchesBtn",
    "moreBatchesContainer",
    "moreTeamsBtn",
    "moreTeamsContainer",
    "loadingContainer",
    "inviteEmployeeValidationErrorsContainer",
    "inviteEmployeeValidationErrorMessage",
    "inviteEmployeePersonalDetailsValidationErrorsContainer",
    "inviteEmployeePersonalDetailsValidationErrorMessage",
    "sendInviteBtn",
    "personalDetailsForm"
  ]

  initialize() {
    this.selectedLndTeamIds = []
    this.selectedLndBatchIds = []
  }

  connect() {}

  openInviteEmployeeModal(event){
    $("#invite-employee-modal").removeClass("hidden")
    $("#invite-employee-modal").css("display", "flex")
    $("#new-employee-invite-container").removeClass("hidden")
    this._enableTagifyForTextfields()

    console.log(event.currentTarget.dataset);
    TmoEvents.logAsync("TLND_EMPLOYEES_INVITE_EMPLOYEES_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "employees_invite_employees_btn", btn_name: "Invite employee" })
    this._getCourses()
  }

  closeInviteEmployeeModal() {
    $("#invite-employee-modal").addClass("hidden")
    $("#invite-employee-modal").css("display", "none")
    this._updateUIAndStates()
  }

  clearFormToAddAnotherEmployee() {
    this._updateUIAndStates()
    this._enableTagifyForTextfields()
    TmoEvents.logAsync("TLND_EMPLOYEES_SINGLE_INVITE_SUCCESS_ADD_ANOTHER_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "employees_single_invite_success_add_another_btn" })
  }

  hideInviteEmployeeStep1ValidationErrorsContainer(event) {
    this.inviteEmployeeStep1ValidationErrorsContainerTarget.classList.add("hidden")
    this.inviteEmployeeStep1ValidationErrorMessageTarget.value = ""
  }

  selectTeam({ params }) {
    this._updateSelectedTeamBtnStateAndPopulateArray(params.selectedTeamId, "selectTeamButton")
  }

  selectBatch({ params }) {
    this._updateSelectedBatchBtnStateAndPopulateArray(params.selectedBatchId, "selectBatchButton")
  }

  showMoreCoursesLoading() {
    this.moreCoursesBtnTarget.classList.add("hidden")
    this.moreCoursesContainerTarget.classList.remove("hidden")
    this.showLoadingSymbol()
  }

  showMoreBatchesLoading() {
    this.moreBatchesBtnTarget.classList.add("hidden")
    this.moreBatchesContainerTarget.classList.remove("hidden")
    this.showLoadingSymbol()
  }

  showMoreTeamsLoading() {
    this.moreTeamsBtnTarget.classList.add("hidden")
    this.moreTeamsContainerTarget.classList.remove("hidden")
    this.showLoadingSymbol()
  }

  copyInviteLink() {
    // Create an auxiliary hidden input
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(this.inviteLink);
    }

    // Create an auxiliary hidden input
    var aux = document.createElement("input");

    // Get the text from the element passed into the input
    aux.setAttribute("value", this.inviteLink);

    // Append the aux input to the body
    document.body.appendChild(aux);

    // Highlight the content
    aux.select();

    console.log('copy success?', document.execCommand('copy'));
    document.body.removeChild(aux);
    $(".tool-tip-invite-text").text('Copied')
  }

  saveAndNext(event) {
    this.inviteEmployeePersonalDetailsValidationErrorsContainerTarget.classList.add("hidden")
    if(this._areAllPersonalDetailsAdded() == true) {
      this.fillUpDetailsIconTarget.classList.add("hidden")
      this.allDetailsAddedIconTarget.classList.remove("hidden")
      $("#teams-and-batches-tab").addClass("active")
      $("#employee-personal-details-tab").removeClass("active")
      $("#teamsAndBatches").addClass("active")
      $("#teamsAndBatches").addClass("show")
      $("#personalDetails").removeClass("active")
      $("#personalDetails").removeClass("show")
    } else {
      this.inviteEmployeePersonalDetailsValidationErrorsContainerTarget.classList.remove("hidden")
      this.inviteEmployeePersonalDetailsValidationErrorMessageTarget.innerHTML = "Please fill all the mandatory fields"
    }
  }

  sendInvite() {
    this._showLoadingOnSendingInvite()

    if(this._isAnyFieldInvalid()) {
      this._hideLoadingOfSendInvite()
      this._showErrorMessageForInvalidFields()
      return
    }

    $.ajax({
      type: 'post',
      url: '/api/web/admin/user_management/employee/invite-and-notify.json',
      data: {
        email: this.employeeEmailInputTarget.value,
        first_name: this.employeeFirstNameInputTarget.value,
        last_name: this.employeeLastNameInputTarget.value,
        employee_id: this.employeeIdInputTarget.value,
        calling_code_country_id: this.callingCodeInputTarget.value,
        mobile_number: this.employeeMobileNumberInputTarget.value,
        job_designation_id: this.jobDesignationInputTarget.value,
        base_location_id: this.baseLocationInputTarget.value,
        joining_date: this.employeeJoiningDateInputTarget.value,
        team_ids: this.selectedLndTeamIds,
        batch_ids: this.selectedLndBatchIds
       },
      success: function(response) {
        this.inviteLink = response.invite_link
        this._showEmailSentContainer(response.title, response.message)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this._hideLoadingOfSendInvite()
        this.inviteEmployeeValidationErrorsContainerTarget.classList.remove("hidden")
        this.inviteEmployeeValidationErrorMessageTarget.classList.remove("hidden")
        this.inviteEmployeeValidationErrorMessageTarget.innerHTML = xhr.responseJSON.error_message
      }.bind(this)
    })
  }

  moreTeams({ params }) {
    this.showMoreTeamsLoading()
    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/more-teams',
      data: {
        team_ids: params.moreTeamsIds
       },
      success: function(response) {
        this.hideLoadingSymbol()
        this.moreTeamsContainerTarget.innerHTML = JSON.parse(response)
      }.bind(this),
      error: function(xhr, error){
        this.moreTeamsContainerTarget.classList.add("hidden")
        console.log(error)
      }.bind(this)
    })
  }

  moreBatches({ params }) {
    this.showMoreBatchesLoading()
    let batchIds = params.moreBatchesIds
    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/more-batches',
      data: {
        batch_ids: batchIds
       },
      success: function(response) {
        this.hideLoadingSymbol()
        this.moreBatchesContainerTarget.innerHTML = JSON.parse(response)
      }.bind(this),
      error: function(xhr, error){
        this.moreBatchesContainerTarget.classList.add("hidden")
        console.log(error)
      }.bind(this)
    })
  }

  moreCourses({ params }) {
    this.showMoreCoursesLoading()
    let courseIds = params.moreCoursesIds
    console.log(courseIds)
    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/more-courses',
      data: {
        course_ids: courseIds
       },
      success: function(response) {
        this.hideLoadingSymbol()
        this.moreCoursesContainerTarget.innerHTML = JSON.parse(response)
      }.bind(this),
      error: function(xhr, error){
        this.moreCoursesContainerTarget.classList.add("hidden")
        console.log(error)
      }.bind(this)
    })
  }

  _getCourses() {
    this.sendInviteBtnTarget.classList.add("disable")

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employee/auto-enrollment-courses',
      data: {
        team_ids: this.selectedLndTeamIds,
        batch_ids: this.selectedLndBatchIds
      },
      success: function(response) {
        $("#assigned-courses").removeClass("hidden")
        $("#assigned-courses").html(JSON.parse(response))
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.inviteEmployeeValidationErrorsContainerTarget.classList.remove("hidden")
        this.inviteEmployeeValidationErrorMessageTarget.classList.remove("hidden")
        this.inviteEmployeeValidationErrorMessageTarget.innerHTML = xhr.responseJSON.error_message
      }.bind(this)
    })
  }

  _showEmailSentContainer(title, message) {
    this._hideLoadingOfSendInvite()
    this.inviteEmployeeValidationErrorsContainerTarget.classList.add("hidden")
    $("#new-employee-invite-container").addClass("hidden")
    $("#email-sent-to-employee-container").removeClass("hidden")
  }

  _enableTagifyForTextfields() {
    this.tagifyForTeam = new Tagify(this.assignTeamInputTarget, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value)
    })
    this.tagifyForTeam.on('remove', this._removeTeamTag.bind(this))
    this.tagifyForTeam.on('focus', this._suggestionForLndTeams.bind(this))
    this.tagifyForTeam.on('dropdown:select', this._dropdownSelectedTeam.bind(this))

    this.tagifyForBatch = new Tagify(this.assignBatchInputTarget, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value)
    })
    this.tagifyForBatch.on('remove', this._removeBatchTag.bind(this))
    this.tagifyForBatch.on('focus', this._suggestionForLndBatches.bind(this))
    this.tagifyForBatch.on('dropdown:select', this._dropdownSelectedBatch.bind(this))
  }

  _removeTeamTag(e) {
    var data = e.detail.data
    if(this.selectedLndTeamIds.includes(data.teamId)) {
      var index = this.selectedLndTeamIds.indexOf(data.teamId)
      this.selectedLndTeamIds.splice(index, 1)
      this._addTeamBackToList(data.teamId)
    }

    this._getCourses()
  }

  _removeBatchTag(e) {
    var data = e.detail.data
    if(this.selectedLndBatchIds.includes(data.batchId)) {
      var index = this.selectedLndBatchIds.indexOf(data.batchId)
      this.selectedLndBatchIds.splice(index, 1)
      this._addBatchBackToList(data.batchId)
    }

    this._getCourses()
  }

  _showLoadingOnSendingInvite() {
    $("#invite-employee-confirmation-loading-symbol").removeClass("hidden");
    this.sendInviteBtnTarget.classList.add("hidden")
  }

  _hideLoadingOfSendInvite() {
    $("#invite-employee-confirmation-loading-symbol").addClass("hidden");
    this.sendInviteBtnTarget.classList.remove("hidden")
  }

  showLoadingSymbol() {
    this.loadingContainerTarget.classList.remove('hidden')
  }

  hideLoadingSymbol() {
    this.loadingContainerTarget.classList.add('hidden')
  }

  _isAnyFieldInvalid() {
    return this._areAllPersonalDetailsAdded() == false ||
           this.selectedLndTeamIds.length == 0
  }

  _areAllPersonalDetailsAdded() {
    return (this.employeeEmailInputTarget.value != null && this.employeeEmailInputTarget.value != "") &&
           (this.employeeFirstNameInputTarget.value != null && this.employeeFirstNameInputTarget.value != "") &&
           (this.employeeLastNameInputTarget.value !=  null && this.employeeLastNameInputTarget.value != "")
  }

  _showErrorMessageForInvalidFields() {
    this.sendInviteBtnTarget.classList.remove("hidden")
    this.inviteEmployeeValidationErrorsContainerTarget.classList.remove("hidden")
    this.inviteEmployeeValidationErrorMessageTarget.innerHTML = "Email should not be blank"

    if(this.employeeEmailInputTarget.value == null || this.employeeEmailInputTarget.value == "") {
      this.inviteEmployeeValidationErrorMessageTarget.innerHTML = "Email should not be blank"
    } else if(this.employeeFirstNameInputTarget.value == null || this.employeeFirstNameInputTarget.value == "") {
        this.inviteEmployeeValidationErrorMessageTarget.innerHTML = "First name should not be blank"
    } else if(this.employeeLastNameInputTarget.value == null || this.employeeLastNameInputTarget.value == "") {
      this.inviteEmployeeValidationErrorMessageTarget.innerHTML = "Last name should not be blank"
    } else if(this.selectedLndTeamIds.length == 0) {
      this.inviteEmployeeValidationErrorMessageTarget.innerHTML = "Please select atleast one team"
    }
  }

  _getStringAfterRemovingExtraSpace(textToRemoveSpace){
    return textToRemoveSpace.replace(/\s+/g, ' ').trim()
  }

  _clearForm() {
    this.employeeEmailInputTarget.value = ""
    this.employeeFirstNameInputTarget.value = ""
    this.employeeLastNameInputTarget.value = ""
    this.employeeIdInputTarget.value = ""
    this.callingCodeInputTarget[0].selected = true
    this.jobDesignationInputTarget[0].selected = true
    this.baseLocationInputTarget[0].selected = true
    this.employeeMobileNumberInputTarget.value = ""
    this.employeeJoiningDateInputTarget.value = ""
    this.assignTeamInputTarget.value = ""
    this.assignBatchInputTarget.value = ""
    this._clearSelectedTeams()
    this._clearSelectedBatches()
    this._removeTagifyTags()

    this._hideMoreTeams()
    this._hideMoreBatches()
  }

  _clearSelectedTeams() {
    this.selectedLndTeamIds.forEach((lndTeamId) => {
      this._addTeamBackToList(lndTeamId)
      this.tagifyForTeam.removeTags(this.tagifyForTeam.value.find(tag => { return tag.teamId == lndTeamId }).value)
    })
    this.tagifyForTeam = null
  }

  _clearSelectedBatches() {
    this.selectedLndBatchIds.forEach((lndBatchId) => {
      this._addBatchBackToList(lndBatchId)
      this.tagifyForBatch.removeTags(this.tagifyForBatch.value.find(tag => { return tag.batchId == lndBatchId }).value)
    })

    this.tagifyForBatch = null
  }

  _removeTagifyTags() {
    document.querySelectorAll(".assign_team_container .tagify").forEach(
      function(tag, index, array) {
        tag.remove()
      }
    )

    document.querySelectorAll(".assign_batch_container .tagify").forEach(
      function(tag, index, array) {
        tag.remove()
      }
    )
  }

  _addTeamBackToList(selectedLndTeamId) {
    $(`button[id='lnd-team-btn'][data-selected-team-id='${selectedLndTeamId}']`)[0].classList.remove("hidden")
  }

  _addBatchBackToList(selectedLndBatchId) {
    $(`button[id='lnd-batch-btn'][data-selected-batch-id='${selectedLndBatchId}']`)[0].classList.remove("hidden")
  }

  _updateUIAndStates() {
    this._clearForm()
    this._enablePersonalDetailsTab()
    $("#new-employee-invite-container").removeClass("hidden")
    $("#email-sent-to-employee-container").addClass("hidden")
    this.inviteEmployeeValidationErrorsContainerTarget.classList.add("hidden")
    this.inviteEmployeePersonalDetailsValidationErrorsContainerTarget.classList.add("hidden")
  }

  _enablePersonalDetailsTab() {
    $("#employee-personal-details-tab").addClass("active")
    $("#teams-and-batches-tab").removeClass("active")
    $("#personalDetails").addClass("active")
    $("#personalDetails").addClass("show")
    $("#teamsAndBatches").removeClass("active")
    $("#teamsAndBatches").removeClass("show")
    this.fillUpDetailsIconTarget.classList.remove("hidden")
    this.allDetailsAddedIconTarget.classList.add("hidden")
  }

  _suggestionForLndTeams(e) {
    var value = e.detail.value
    this.tagifyForTeam.whitelist = null // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    this.tagifyForTeam.loading(true).dropdown.hide()

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/lnd-teams-suggestions',
      data: {
        query: value
       },
      success: function(response) {
        console.log(response);
        var lndTeams = []
        response.lndTeams.forEach((lndTeam) => {
          lndTeams.push({"value": lndTeam.name, "teamId": lndTeam.id})
        })
        this.tagifyForTeam.whitelist = lndTeams
        this.tagifyForTeam.loading(false).dropdown.show(value)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.tagifyForTeam.loading(false).dropdown.hide()
      }.bind(this)
    })
  }

  _dropdownSelectedTeam(e) {
    var data = e.detail.data
    console.log(data)
    this._updateSelectedTeamBtnStateAndPopulateArray(data.teamId, "dropdown")
  }

  _updateSelectedTeamBtnStateAndPopulateArray(selectedTeamId, selectionType) {
    this.selectedLndTeamIds.push(selectedTeamId)
    var selectedButton = $(`button[id='lnd-team-btn'][data-selected-team-id='${selectedTeamId}']`)[0]
    selectedButton.classList.add('hidden')

    if(selectionType != "dropdown") {
      var tags = []
      tags.push({"value": this._getStringAfterRemovingExtraSpace(selectedButton.innerText), "teamId": selectedTeamId});
      this.tagifyForTeam.addTags(tags);
    }
    this._getCourses()
  }

  _suggestionForLndBatches(e) {
    var value = e.detail.value
    this.tagifyForBatch.whitelist = null // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    this.tagifyForBatch.loading(true).dropdown.hide()

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/lnd-batches-suggestions',
      data: {
        query: value
       },
      success: function(response) {
        console.log(response);
        var lndBatches = []
        response.lndBatches.forEach((lndBatch) => {
          lndBatches.push({"value": lndBatch.name, "batchId": lndBatch.id})
        })
        this.tagifyForBatch.whitelist = lndBatches
        this.tagifyForBatch.loading(false).dropdown.show(value)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.lndBatches.loading(false).dropdown.hide()
      }.bind(this)
    })
  }

  _dropdownSelectedBatch(e) {
    var data = e.detail.data
    this._updateSelectedBatchBtnStateAndPopulateArray(data.batchId, "dropdown")
  }

  _updateSelectedBatchBtnStateAndPopulateArray(selectedBatchId, selectionType) {
    this.selectedLndBatchIds.push(selectedBatchId)
    var selectedButton = $(`button[id='lnd-batch-btn'][data-selected-batch-id='${selectedBatchId}']`)[0]
    selectedButton.classList.add('hidden')

    if(selectionType != "dropdown") {
      var tags = []
      tags.push({"value": this._getStringAfterRemovingExtraSpace(selectedButton.innerText), "batchId": selectedBatchId});
      this.tagifyForBatch.addTags(tags);
    }
    this._getCourses()
  }

  _hideMoreTeams() {
    if(this.hasMoreTeamsBtnTarget){
      this.moreTeamsBtnTarget.classList.remove("hidden")
      this.moreTeamsContainerTarget.classList.add("hidden")
    }
  }

  _hideMoreBatches() {
    if(this.hasMoreBatchesBtnTarget){
      this.moreBatchesBtnTarget.classList.remove("hidden")
      this.moreBatchesContainerTarget.classList.add("hidden")
    }
  }
}
