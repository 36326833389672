import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import { PubSub } from 'pubsub-js'
import { unsubscribeTokens } from 'shared/utils/utils'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = [
    "thumbnailImage",
    "previewScreenshot",
    "installThemeStyleForm",
    "themeIdHiddenField",
    "selectedThemeStyleIdHiddenField",
    "copyContentHiddenField",
    "addToMyThemesButton",
    "installThemeButtonsContainer",
    "statusMessage",
    "confirmAddToMyThemesStatusMessage",
    "themeStyleButton",
    "previewModal",
    "previewModalThemesList",
    "previewModalThemesListItem",
    "previewModalThemesListItemTemplate",
    "confirmAddToMyThemesModal",
    "previewThemeName",
    "previewThemeDescription",
    "closeConfirmAddToMyThemesModalButton",
    "previewThemeStyleTabButtonsContainer"
  ]

  initialize(){
    this.subscriptions = []
    this.originalTmMainContentHolderZIndex = document.getElementById('tmMainContentHolder').style.getPropertyValue('z-index')
  }

  connect(){
    console.log("connected Show controller")
    // this.installedThemeStyleIds = JSON.parse(this.data.get("installedThemeStyleIds"))
    this.themes = []
    this.selectedThemeId = null
    this.selectedTheme = null
    this.selectedThemeStyle = null

    // this.subscriptions.push(PubSub.subscribe(EVENTS.TAB.ACTIVATED, function (msg, data){ this._selectTheme(msg,data)}.bind(this)))
    this._loadThemes()
  }

  disconnect(){
    unsubscribeTokens(this.subscriptions)
  }

  // Only on click of preview button
  openPreviewModal(e){
    TmoEvents.logAsync("TMO_THEME_PREVIEW_CLICKED", { ui_theme_id: e.target.dataset.uiThemeId })

    // hack to not show topNav above/on the modal background
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
    this.previewModalTarget.style.display = "block"

    this.selectTheme(e.target.dataset.uiThemeId)
  }

  openConfirmAddToMyThemesModal(e){
    TmoEvents.logAsync("TMO_ADD_TO_MY_THEMES", { ui_theme_id: this.selectedTheme.id, selected_ui_theme_style_id: this.selectedThemeStyle.id })
    this.confirmAddToMyThemesModalTarget.style.display = "block"
  }

  closePreviewModal(){
    // this.hideTestEmailFailedAlert()
    // this.hideTestEmailSucceededAlert()
    this.previewModalTarget.style.display = "none"
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.originalTmMainContentHolderZIndex)
  }

  closeConfirmAddToMyThemesModal(){
    this.confirmAddToMyThemesModalTarget.style.display = "none"
  }

  changeTheme(event){
    this.selectTheme(this.themes.find(obj => { return obj.id == event.target.dataset.uiThemeId }).id, false)
  }

  selectTheme(themeId, scrollIntoView = true){
    this.selectedTheme = this.themes.find(obj => { return obj.id == themeId })
    this.previewModalThemesListItemTargets.forEach( themeListItemTarget => {
      if(this.selectedTheme.id == themeListItemTarget.dataset.uiThemeId){
        themeListItemTarget.classList.add('border-primary-600', 'border-2')
        themeListItemTarget.classList.remove('border-gray-200', 'border')
        if(scrollIntoView){themeListItemTarget.scrollIntoView()}
      } else {
        themeListItemTarget.classList.add('border-gray-200', 'border')
        themeListItemTarget.classList.remove('border-primary-600', 'border-2')
      }
    }, this)
    this.themeIdHiddenFieldTarget.value = this.selectedTheme.id
    this.selectedThemeStyles = this.selectedTheme.ui_theme_styles
    this.previewThemeNameTarget.innerHTML = this.selectedTheme.name
    this.previewThemeDescriptionTarget.innerHTML = this.selectedTheme.description
    this._updateThemeStyleTabButtons()

    this.selectThemeStyle(this.selectedThemeStyles[0].id)
  }

  changeThemeStyle(e){
    this.selectThemeStyle(this.selectedThemeStyles.find(obj => { return obj.id == e.target.dataset.uiThemeStyleId }).id)
  }
  selectThemeStyle(themeStyleId){
    console.log("action selectThemeStyle")

    this.selectedThemeStyle =  this.selectedThemeStyles.find(obj => { return obj.id == themeStyleId })
    this.selectedThemeStyleIdHiddenFieldTarget.value = this.selectedThemeStyle.id

    $('button[name="themeStyleTabButton"]').removeClass('bg-white text-gray-900 shadow').addClass('text-gray-600 group-hover:text-gray-900')
    $(`button[name="themeStyleTabButton"]:button[data-ui-theme-style-id='${this.selectedThemeStyle.id}']`).removeClass('text-gray-600 group-hover:text-gray-900').addClass('bg-white text-gray-900 shadow')

    this.previewScreenshotTarget.src = this.selectedThemeStyle.preview_screenshot_image_url

    if(this.selectedThemeStyle.is_added){
      this.addToMyThemesButtonTarget.classList.add('hidden')
      this.statusMessageTarget.classList.remove('hidden')
      this.statusMessageTarget.innerHTML = "Theme added"
    } else {
      this.addToMyThemesButtonTarget.classList.remove('hidden')
      this.statusMessageTarget.classList.add('hidden')
      this.statusMessageTarget.innerHTML = ""
    }

  }

  installTheme(e){
    // this.closeConfirmAddToMyThemesModal()
    this.closeConfirmAddToMyThemesModalButtonTarget.classList.add('hidden')
    this.copyContentHiddenFieldTarget.value = e.target.dataset.copyContent
    this.installThemeButtonsContainerTarget.classList.add('hidden')
    this.addToMyThemesButtonTarget.classList.add('hidden')
    this.statusMessageTarget.classList.remove('hidden')
    this.confirmAddToMyThemesStatusMessageTarget.classList.remove('hidden')
    this.statusMessageTarget.innerHTML = "Adding theme..."
    this.confirmAddToMyThemesStatusMessageTarget.innerHTML = "Adding theme..."
    Rails.fire(this.installThemeStyleFormTarget, 'submit')
  }

  installedThemeStyleSuccessfully(){
    console.log("Theme style added successfully")
  }

  failedToInstallThemeStyle(){
    console.log("Failed to add theme style")
  }

  _loadThemes(){
    this._getThemes().then(
      function(data, status, xhr){
        this.themes = data.ui_themes
        console.log("themes loaded")
      }.bind(this),
      function(jqXHR, status, error){
        console.log(error)
      }.bind(this)
    )
  }

  _updateThemeStyleTabButtons(){

    this.previewThemeStyleTabButtonsContainerTarget.innerHTML = ""

    this.selectedThemeStyles.forEach( function (themeStyle) {
      let btnElement = document.createElement("button")
      btnElement.dataset.uiThemeStyleId = themeStyle.id
      btnElement.dataset.action = "click->design--ui--themes#changeThemeStyle"
      btnElement.setAttribute("name","themeStyleTabButton")
      btnElement.classList.add('flex', 'w-24', 'items-center', 'justify-center', 'px-3', 'py-1.5', 'rounded-md', 'text-sm', 'font-medium', 'focus:outline-none', 'text-gray-600', 'group-hover:text-gray-900', 'cursor-pointer')
      btnElement.innerHTML = themeStyle.name

      this.previewThemeStyleTabButtonsContainerTarget.appendChild(btnElement)
    }.bind(this))
  }

  _getThemes(ui_theme_id){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: `/api/web/admin/design/ui/themes.json`,
      method: "GET",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }
}
