import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = [
    "teamManagerInput",
    "teamManagerIds",
    "form"
  ]

  initialize() {}

  connect() {
    this.selectedTeamManagerIds = []
    this._enableTagifyForTeamManager()
  }

  confirmButtonClicked() {
    this.teamManagerIdsTarget.value = this.selectedTeamManagerIds
    Rails.fire(this.formTarget, 'submit')
  }

  _enableTagifyForTeamManager() {
    var teamManagers = JSON.parse(this.teamManagerInputTarget.value)
    this.tagifyForTeamManager = new Tagify(this.teamManagerInputTarget, {
      enforceWhitelist: true,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value),
      templates: {
        dropdownItemNoMatch: function(data) {
          return `<div class='${this.settings.classNames.dropdownItem}' value="noMatch" tabindex="0" role="option">
              No suggestion found for: <strong>${data.value}</strong>
          </div>`
        }
      }
    })
    this.tagifyForTeamManager.on('remove', this._removeTeamManagerTag.bind(this))
    this.tagifyForTeamManager.on('focus', this._suggestionForTeamManagers.bind(this))
    this.tagifyForTeamManager.on('dropdown:select', this._dropdownSelectedTeamManager.bind(this))

    var tags = []
    teamManagers.forEach((teamManager) => {
      tags.push({"value": teamManager.manager_full_name, "teamManagerId": teamManager.manager_id});
      this.selectedTeamManagerIds.push(teamManager.manager_id)
    })

    this.tagifyForTeamManager.whitelist = tags

    this.tagifyForTeamManager.addTags(tags);
  }

  _removeTeamManagerTag(e) {
    var data = e.detail.data
    if(this.selectedTeamManagerIds.includes(data.teamManagerId)) {
      var index = this.selectedTeamManagerIds.indexOf(data.teamManagerId)
      this.selectedTeamManagerIds.splice(index, 1)
    }
  }

  _suggestionForTeamManagers(e) {
    var value = e.detail.value
    this.tagifyForTeamManager.whitelist = null // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    this.tagifyForTeamManager.loading(true).dropdown.hide()

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/team/managers',
      data: {
        query: value
       },
      success: function(response) {
        console.log(response);
        var lndTeamManagers = []
        response.lndTeamManagers.forEach((lndTeamManager) => {
          lndTeamManagers.push({"value": lndTeamManager.fullName, "teamManagerId": lndTeamManager.id})
        })
        this.tagifyForTeamManager.whitelist = lndTeamManagers
        this.tagifyForTeamManager.loading(false).dropdown.show(value)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.tagifyForTeamManager.loading(false).dropdown.hide()
      }.bind(this)
    })
  }

  _dropdownSelectedTeamManager(e) {
    var data = e.detail.data
    console.log(data)
    if(data != undefined) {
      var selectedTeamManagerId = data.teamManagerId
      this.selectedTeamManagerIds.push(selectedTeamManagerId)
    }
  }
}
