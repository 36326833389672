export class FbGtmTracker {
  static trackAdminDashboardEvent(eventName, eventData) {
    $.ajax({
      url: '/api/web/admin/fb_gtm_tracker/tracking_data',
      method: "get",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(trackingData) {
        Object.entries(eventData).forEach(([key, value]) =>
          trackingData[key] = value
        );

        this._trackFacebookEvent(eventName, trackingData)
        this._trackGtmEnhancedData(eventName, trackingData)
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }

  static trackOnboardingEvent(eventName, eventData) {
    $.ajax({
      url: '/api/fb_gtm_tracker/tracking_data',
      method: "get",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(trackingData) {
        Object.entries(eventData).forEach(([key, value]) =>
          trackingData[key] = value
        );

        this._trackFacebookEvent(eventName, trackingData)
        this._trackGtmEnhancedData(eventName, trackingData)
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }

  static _trackFacebookEvent(eventName, trackingData) {
    fbq('trackCustom', eventName, trackingData);
  }

  static _trackGtmEnhancedData(eventName, trackingData) {
    dataLayer.push({ 'event': eventName, 'enhanced_conversion_data': trackingData })
  }
}
