import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = [
    "batchNameInput",
    "form",
    "createBatchBtn",
    "errorMessage"
  ]

  initialize() {}

  connect() {
  }

  openCreateBatchModal(event){
    $("#lnd--batch-modal").removeClass("hidden")
    $("#lnd--batch-modal").css("display", "flex")
    console.log(event.currentTarget.dataset);
    TmoEvents.logAsync("TLND_BATCHES_CREATE_NEW_BATCH_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "batches_create_new_batch_btn", btn_name: "Create new batch" })
  }

  closeCreateBatchModal() {
    $("#lnd--batch-modal").addClass("hidden")
    $("#lnd--batch-modal").css("display", "none")
  }

  confirmButtonClicked() {
    Rails.fire(this.formTarget, 'submit')
  }

  toggleConfirmButtonVisibility() {
    if(this._isBatchNameEmpty() == true) {
      this._disableConfirmBtn()
      this._showErrorMessage("Please enter valid batch name")
    } else {
      this._enableConfirmBtn()
      this._hideErrorMessage()
    }
  }

  _isBatchNameEmpty() {
    return this.batchNameInputTarget.value == null || this.batchNameInputTarget.value == ""
  }

  _clearForm() {
    this.batchNameInputTarget.value = ""
  }

  _showErrorMessage(errorMessage) {
    this.errorMessageTarget.classList.remove("hidden")
    this.errorMessageTarget.innerHTML = errorMessage
  }

  _hideErrorMessage() {
    this.errorMessageTarget.classList.add("hidden")
  }

  _enableConfirmBtn() {
    this.createBatchBtnTarget.classList.remove("bg-gray-300", "text-white", "cursor-not-allowed", "pointer-events-none")
    this.createBatchBtnTarget.classList.add("btn-primary", "cursor-pointer")
    this.createBatchBtnTarget.removeAttribute("disabled")
  }

  _disableConfirmBtn() {
    this.createBatchBtnTarget.classList.add("bg-gray-300", "text-white", "cursor-not-allowed", "pointer-events-none")
    this.createBatchBtnTarget.classList.remove("btn-primary", "cursor-pointer")
    this.createBatchBtnTarget.setAttribute("disabled", true)
  }
}
