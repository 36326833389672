import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = [
    "creatorSellsOnlineCoursesBtn",
    "creatorDoesNotSellOnlineCoursesBtn",
    "doesCreatorSellOnlineCouresHiddenField",

    "doesCreatorHaveContentContainer",
    "creatorHasContentBtn",
    "creatorHasContentBtnSelectedImg",
    "creatorDoesNotHaveContentBtn",
    "creatorDoesNotHaveContentBtnSelectedImg",
    "doesCreatorHaveContentHiddenField",

    "nextBtn"
  ];

  initialize(){}

  connect(){
    FbGtmTracker.trackOnboardingEvent('TMO_CREATOR_INTRO_VIEWED', {});
  }

  disconnect(){}

  enableNextButton() {
    this.nextBtnTarget.disabled = false
    this.nextBtnTarget.classList.remove("cursor-not-allowed")
    this.nextBtnTarget.removeAttribute("style")
    this.nextBtnTarget.classList.add("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  disableNextButton() {
    this.nextBtnTarget.disabled = true
    this.nextBtnTarget.classList.add("cursor-not-allowed")
    this.nextBtnTarget.setAttribute("style", "background-color: #dbe2ea;");
    this.nextBtnTarget.classList.remove("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  creatorSellsOnlineCoursesBtnClicked() {
    this.creatorSellsOnlineCoursesBtnTarget.classList.add("border-green-400", "bg-green-100")
    this.creatorDoesNotSellOnlineCoursesBtnTarget.classList.remove("border-green-400", "bg-green-100")
    this.doesCreatorHaveContentContainerTarget.classList.add("hidden")
    this.doesCreatorSellOnlineCouresHiddenFieldTarget.value = true

    // Remove creator does not have content applied classes
    this.creatorHasContentBtnTarget.classList.remove("border", "border-green-400")
    this.creatorDoesNotHaveContentBtnTarget.classList.remove("border", "border-green-400")
    this.creatorHasContentBtnSelectedImgTarget.classList.add("hidden")
    this.creatorDoesNotHaveContentBtnSelectedImgTarget.classList.add("hidden")

    this.enableNextButton()
    this._trackButtonClicks('TMO_CREATOR_INTRO_SELLS_ONLINE_COURSES_CLICKED', 'creator_intro_sells_online_courses')
    FbGtmTracker.trackOnboardingEvent('TMO_CREATOR_INTRO_SELLS_ONLINE_COURSES_CLICKED', {});
  }

  creatorDoesNotSellOnlineCoursesBtnClicked() {
    this.creatorSellsOnlineCoursesBtnTarget.classList.remove("border-green-400", "bg-green-100")
    this.creatorDoesNotSellOnlineCoursesBtnTarget.classList.add("border-green-400", "bg-green-100")
    this.doesCreatorSellOnlineCouresHiddenFieldTarget.value = false
    this.doesCreatorHaveContentContainerTarget.classList.remove("hidden")
    this.disableNextButton()
    this._trackButtonClicks('TMO_CREATOR_INTRO_DOES_NOT_SELL_ONLINE_COURSES_CLICKED', 'creator_intro_does_not_sell_online_courses')
    FbGtmTracker.trackOnboardingEvent('TMO_CREATOR_INTRO_DOES_NOT_SELL_ONLINE_COURSES_CLICKED', {});
  }

  creatorHasContentBtnClicked() {
    this.creatorHasContentBtnTarget.classList.add("border", "border-green-400")
    this.creatorDoesNotHaveContentBtnTarget.classList.remove("border", "border-green-400")
    this.doesCreatorHaveContentHiddenFieldTarget.value = true
    this.creatorHasContentBtnSelectedImgTarget.classList.remove("hidden")
    this.creatorDoesNotHaveContentBtnSelectedImgTarget.classList.add("hidden")
    this.enableNextButton()
    this._trackButtonClicks('TMO_CREATOR_INTRO_RUNS_ONLINE_CONTENT_CHANNEL_CLICKED', 'creator_intro_runs_online_content_channel')
    FbGtmTracker.trackOnboardingEvent('TMO_CREATOR_INTRO_RUNS_ONLINE_CONTENT_CHANNEL_CLICKED', {});
  }

  creatorDoesNotHaveContentBtnClicked() {
    this.creatorHasContentBtnTarget.classList.remove("border", "border-green-400")
    this.creatorDoesNotHaveContentBtnTarget.classList.add("border", "border-green-400")
    this.doesCreatorHaveContentHiddenFieldTarget.value = false
    this.creatorHasContentBtnSelectedImgTarget.classList.add("hidden")
    this.creatorDoesNotHaveContentBtnSelectedImgTarget.classList.remove("hidden")
    this.enableNextButton()
    this._trackButtonClicks('TMO_CREATOR_INTRO_DOES_NOT_RUN_ONLINE_CONTENT_CHANNEL_CLICKED', 'creator_intro_does_not_run_online_content_channel')
    FbGtmTracker.trackOnboardingEvent('TMO_CREATOR_INTRO_DOES_NOT_RUN_ONLINE_CONTENT_CHANNEL_CLICKED', {});
  }

  _trackButtonClicks(eventName, btnSrc) {
    $.ajax({
      url: '/api/creator-intro/track-events',
      method: "post",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      data: {
        event_name: eventName,
        btn_src: btnSrc
       },
      success: function(data) {
        console.log("event logged");
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }

  trackFbGtmEventsForNextButtonClick() {
    FbGtmTracker.trackOnboardingEvent('TMO_CREATOR_INTRO_NEXT_BUTTON_CLICKED', {});
  }
}
