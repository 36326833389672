import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = []

  initialize() {}

  openRegisterDemoModal(event){
    this._trackRegisterDemoButtonClicked()
    FbGtmTracker.trackAdminDashboardEvent('TMO_DEMO_INITIATED', {});
    $("#register-for-demo-calendly-modal").removeClass("hidden")
    $("#register-for-demo-calendly-modal").css("display", "flex")
    $("#register-for-demo-calendly-modal").css("height", window.innerHeight)
  }

  _trackRegisterDemoButtonClicked() {
    $.ajax({
      url: '/api/web/admin/tmo_webinar_app_user_registrations/register_demo_button_clicked?btn_src=sidenav',
      method: "post",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data) {
        console.log("event logged");
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }
}
