import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = [
    "demoPrompt"
  ]

  initialize() {}

  connect() {}

  dismissDemoPrompt() {
    this._markAsdemoPromptDismissed()
    this.demoPromptTarget.classList.add("hidden");
  }

  openRegisterDemoModal(event){
    this._trackRegisterDemoButtonClicked()
    FbGtmTracker.trackAdminDashboardEvent('TMO_DEMO_INITIATED', {});
    this.demoPromptTarget.classList.add("hidden");

    $("#register-for-demo-calendly-modal").removeClass("hidden")
    $("#register-for-demo-calendly-modal").css("display", "flex")
    $("#register-for-demo-calendly-modal").css("max-height", window.innerHeight)

    console.log(event.currentTarget.dataset);
  }

  _markAsdemoPromptDismissed() {
    $.ajax({
      url: `/api/web/admin/demo-nudge/dismissed`,
      method: "post",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data) {
        $(".demoStickyNavFooter").each(function( index, element ) {
          $(element).removeClass("hidden")
        });
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }

  _trackRegisterDemoButtonClicked() {
    $.ajax({
      url: '/api/web/admin/tmo_webinar_app_user_registrations/register_demo_button_clicked?btn_src=nudge',
      method: "post",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data) {
        console.log("event logged");
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }
}
