import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ ]

  initialize(){}

  connect(){
    $('#preview').on('load', function(){ this._client_site_preview_loded() }.bind(this) )
    console.log('connected')
  }

  disconnect(){}

  _client_site_preview_loded(){
    document.getElementById('initialLoadingMessageContainer').style.display = 'none';
    document.getElementById('favicon_preview').style.display = null;
    document.getElementById('preview_loading_indicator').style.display = 'none';
  }
}
