import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "addPaymentGateway"
  ]

  initialize(){}

  openPaymentGatewaysModal(event) {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.addPaymentGatewayTarget,
      "settings--payment-manager--add-payment-gateway"
    );
    modalController.open();

    console.log(event.currentTarget.dataset);
  }
}
