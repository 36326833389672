import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = [
    "employeeInviteExcelInput",
    "validateBulkInviteExcelResultContainer",
    "sendInviteBtn",
    "progressBar",
    "errorMessage",
    "validationResultsHolder",
    "sendInviteBtnHolder"
  ]

  initialize() {
  }

  connect() {
    this.validationStatus = null
  }

  openBulkInviteEmployeeModal(event){
    $("#bulk-invite-employee-modal").removeClass("hidden")
    $("#bulk-invite-employee-modal").css("display", "flex")
    $("#bulk-invite-employee-confirmation-loading-symbol").addClass("hidden")
    $("#bulk-upload-employee-invite-container").removeClass("hidden")
    $("#process-completed-employee-invite-container").addClass("hidden")
    $("#excel-upload-container").removeClass("hidden")
    $("#excel-uploading-container").addClass("hidden")
    this.sendInviteBtnHolderTarget.classList.add("hidden")
    this.validationResultsHolderTarget.classList.add("hidden")
    this._hideValidationResultContainer()
    this._disableSendInviteBtn()

    console.log(event.currentTarget.dataset);
    TmoEvents.logAsync("TLND_EMPLOYEES_BULK_INVITE_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "employees_bulk_invite_btn", btn_name: "Bulk invite" })
  }

  closeBulkInviteEmployeeModal() {
    $("#bulk-invite-employee-modal").addClass("hidden")
    $("#bulk-invite-employee-modal").css("display", "none")
    $("#bulk-invite-employee-confirmation-loading-symbol").addClass("hidden")
    $("#bulk-upload-employee-invite-container").removeClass("hidden")
    $("#process-completed-employee-invite-container").addClass("hidden")
    $("#excel-upload-container").removeClass("hidden")
    $("#excel-uploading-container").addClass("hidden")
    $("#excel-uploaded-container").addClass("hidden")
    this.sendInviteBtnHolderTarget.classList.add("hidden")
    this.validationResultsHolderTarget.classList.add("hidden")
    this.employeeInviteExcelInputTarget.value = ""
    this._hideValidationResultContainer()
    this._disableSendInviteBtn()
  }

  validateExcel() {
    this._updateUIForValidateExcel()
    this.file = this.employeeInviteExcelInputTarget.files[0]
    console.log(this.file)

    var formData = new FormData();
    formData.append('excel_file', this.file, this.file.name)

    $.ajax({
      url: '/api/web/admin/user_management/employees/bulk-invite/validate-excel',
      method: "POST",
      data: formData,
      contentType: false,
      processData: false,
      success: function(response){
        this.validateStatus = "success"
        this._enableSendInviteBtn()
        this._showValidationResultContainer(response.messages, "success")
      }.bind(this),
      error: function(xhr, error) {
        console.log(xhr.responseJSON)
        this.validateStatus = "failed"
        this.employeeInviteExcelInputTarget.value = ""
        this._disableSendInviteBtn()
        this._showValidationResultContainer(xhr.responseJSON.error_messages, "failed")
      }.bind(this)
    })
  }

  sendInvite() {
    if(this.validateStatus != "success") {
      return
    }

    this._showSendInviteLoading()

    $.ajax({
      url: '/api/web/admin/user_management/employees/bulk-invite/create-background-task',
      method: "POST",
      data: {},
      contentType: false,
      processData: false,
      success: function(response){
        this.backgroundTaskId = response.background_task_id
        this.sharedDocument = response.shared_document
        this.apiBaseUrl = `/api/web/admin/shared-documents/${this.sharedDocument.uid}`
        this._uploadDocument()
      }.bind(this),
      error: function(xhr, error) {
        console.log(error)
        this._hideSendInviteLoading()
        this._showValidationResultContainer(xhr.responseJSON.error_messages, "failed")
      }.bind(this)
    })
  }

  _showSendInviteLoading() {
    $("#bulk-invite-employee-send-invite-loading-symbol").removeClass("hidden")
    this.sendInviteBtnTarget.classList.add("hidden")
  }

  _hideSendInviteLoading() {
    $("#bulk-invite-employee-send-invite-loading-symbol").addClass("hidden")
    this.sendInviteBtnTarget.classList.remove("hidden")
  }

  _enableSendInviteBtn() {
    this.sendInviteBtnTarget.removeAttribute("disabled")
    this.sendInviteBtnTarget.classList.remove("bg-gray-300", "text-white", "cursor-not-allowed")
    this.sendInviteBtnTarget.classList.add("btn-primary", "cursor-pointer")
  }

  _disableSendInviteBtn() {
    this.sendInviteBtnTarget.setAttribute("disabled", true)
    this.sendInviteBtnTarget.classList.add("bg-gray-300", "text-white", "cursor-not-allowed")
    this.sendInviteBtnTarget.classList.remove("btn-primary", "cursor-pointer")
  }

  _showValidationResultContainer(messages, status) {
    $("#bulk-invite-employee-confirmation-loading-symbol").addClass("hidden")

    var message_icon = null
    if(status == "failed") {
      message_icon = '<i class="fa fa-exclamation-circle text-red-400 mr-1" aria-hidden="true"></i>'
      $("#excel-upload-container").removeClass("hidden")
      $("#excel-uploading-container").addClass("hidden")
      $("#excel-uploaded-container").addClass("hidden")
    } else {
      message_icon = '<i class="fa fa-check-circle text-green-400 mr-1" aria-hidden="true"></i>'
      $("#excel-upload-container").addClass("hidden")
      $("#excel-uploading-container").addClass("hidden")
      $("#excel-uploaded-container").removeClass("hidden")
    }

    this.validateBulkInviteExcelResultContainerTarget.innerHTML = ""
    this.validateBulkInviteExcelResultContainerTarget.classList.remove("hidden")
    var ulContainer = document.createElement("ul");
    messages.forEach((message, index) => {
      var listContainer = document.createElement("li");
      listContainer.classList.add("text-sm", "text-gray-800", "mb-1")
      listContainer.innerHTML = `${message_icon} ${message}`
      ulContainer.appendChild(listContainer)
    })
    this.validateBulkInviteExcelResultContainerTarget.appendChild(ulContainer)
  }

  _hideValidationResultContainer() {
    this.validateBulkInviteExcelResultContainerTarget.classList.add("hidden")
  }

  _uploadDocument() {
    this._uploadRequestData().then(
      function(uploadRequestData, status, xhr){
        this.uploadRequestData = uploadRequestData
        this._uploadFileToS3();
      }.bind(this),
      function(jqXHR, status, error) {
        console.log(error);
        this._hideSendInviteLoading()
      }.bind(this)
    )
  }

  _uploadRequestData(){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: `${this.apiBaseUrl}/upload-request-data.json`,
      method: "POST",
      data: Object.assign({ filename: this.file.name, file_size: this.file.size, file_type: this.file.type }),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        console.log(jqXHR.response)
        this._hideSendInviteLoading()
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }

  _uploadFileToS3(){
    var form_data = new FormData();
    var data_object = Object.assign(this.uploadRequestData.data, { file: this.file });
    var field_name;
    for(field_name in data_object) {
      form_data.append(field_name, data_object[field_name])
    }
    $.ajax({
      url: this.uploadRequestData.url,
      method: "POST",
      contentType: false,
      processData: false,
      data: form_data,
      xhr: function(){
        // get the native XmlHttpRequest object
        var xhr = $.ajaxSettings.xhr() ;
        // set the onprogress event handler
        xhr.upload.onprogress = function(evt){ console.log(evt.loaded/evt.total*100) }.bind(this);
        // set the onload event handler
        xhr.upload.onload = function(){ console.log('DONE!') } ;
        // return the customized object
        return xhr ;
      }.bind(this),
      success: function(data, status, xhr) {
        this._fileUploadToS3Completed();
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._hideSendInviteLoading()
      }.bind(this)
    });

  }

  _fileUploadToS3Completed() {
    $.ajax({
      url: `/api/web/admin/shared-documents/${this.sharedDocument.uid}/document-uploaded.json`,
      method: "PUT",
      data: Object.assign(
        {
          shared_document_uid: this.sharedDocument.uid,
          filename: this.file.name,
          version: this.uploadRequestData.version,
          file_size: this.file.size,
          file_type: this.file.type
        }
      ),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        this.sharedDocument = data
        this._triggerBackgroundJobForEmployeeInvites()
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._hideSendInviteLoading()
      }.bind(this)
    });
  }

  _triggerBackgroundJobForEmployeeInvites() {
    $.ajax({
      url: '/api/web/admin/user_management/employees/bulk-invite/trigger-background-task',
      method: "POST",
      data: Object.assign({ background_task_id: this.backgroundTaskId }),
      success: function(response) {
        console.log(response);
        this._hideSendInviteLoading()
        $("#bulk-upload-employee-invite-container").addClass("hidden")
        $("#process-completed-employee-invite-container").removeClass("hidden")
      }.bind(this),
      error: function(xhr, error) {
        this._hideSendInviteLoading()
        console.log(error);
      }.bind(this)
    });
  }

  _updateUIForValidateExcel() {
    $("#excel-upload-container").addClass("hidden")
    $("#excel-uploading-container").removeClass("hidden")
    $("#excel-uploaded-container").addClass("hidden")
    $("#bulk-invite-employee-confirmation-loading-symbol").removeClass("hidden")
    this.validationResultsHolderTarget.classList.remove("hidden")
    this.sendInviteBtnHolderTarget.classList.remove("hidden")
  }
}
