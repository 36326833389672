import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "filterTextbox", "filterable", "filterCancelButton" ]

  initialize(){}

  connect(){
    this.appUid = this.data.get('appUid');
  }

  disconnect(){}

  filter(event) {
    this.setVisibilityForClearFilterButton()
    let lowerCaseFilterTerm = this.filterTextboxTarget.value.toLowerCase()

    this.filterableTargets.forEach((el, i) => {
      let filterableKey = el.getAttribute("data-filter-key")

      el.classList.toggle("filter--notFound", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }

  setVisibilityForClearFilterButton() {
    if(this.filterTextboxTarget.value.toString() != "") {
      this.filterCancelButtonTarget.classList.toggle("hidden", false)
    } else {
      this.filterCancelButtonTarget.classList.toggle("hidden", true)
    }
  }

  clearFilter(event) {
    this.filterTextboxTarget.value = ""
    this.filter(event)
    this.setVisibilityForClearFilterButton()
  }
}
