import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = ["featuredProduct", "sortableSection"]

  initialize(){}

  connect(){
    this.updateFeaturedOrderUrl = this.data.get('updateFeaturedOrderUrl')
    this.appId = this.data.get('appId')

    for (var i = 0; i < this.sortableSectionTargets.length; i++) {
      new Sortable(this.sortableSectionTargets[i], {
        handle: '.handle',
        group: 'nested',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65
      });
    }

  }

  disconnect(){}

  updateFeaturedProductSortOrder(){
    this.sortedIds = []
    this.featuredProductTargets
    this.featuredProductTargets.forEach((product, index) => {
      this.sortedIds.push({"product_type": product.dataset["productType"], "product_id": product.dataset["productId"]})
    })
    $.ajax({
      type: "PUT",
      url: this.updateFeaturedOrderUrl,
      data: {"app_id": this.appId, "sorted_ids": this.sortedIds},
      success: function(_data) {
        console.log("sorted!!")
        window.location.href = _data.redirect_url;
      }.bind(this),
      error: function(_data) {
        console.log("Failed!!")
      }.bind(this)
    })
  }
}
