import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

var count = 0;
export default class extends Controller {
  static targets = [
    "ssoApiToken",
    "ssoApiTokenCopyBtn",
    "ssoApiTokenCopyCheck",

  ]

  initialize(){

  }
  connect() {
    console.log("settings--advanced--sso-login controller connected");

  }

  copyBtnUI() {
    this.ssoApiTokenCopyBtnTarget.classList.add("flex");
    this.ssoApiTokenCopyBtnTarget.classList.remove("hidden");
    this.ssoApiTokenCopyCheckTarget.classList.add("hidden");
    this.ssoApiTokenCopyCheckTarget.classList.remove("flex");


  }
  copyApiToken() {
    let ssoApiToken = document.getElementById('sso-api-token')
    ssoApiToken.select();
    document.execCommand('copy')
    var timeout;

      this.ssoApiTokenCopyBtnTarget.classList.add("hidden");
      this.ssoApiTokenCopyBtnTarget.classList.remove("flex");
      this.ssoApiTokenCopyCheckTarget.classList.add("flex");
      this.ssoApiTokenCopyCheckTarget.classList.remove("hidden");
      timeout = setTimeout(()=> this.copyBtnUI(), 2000)
  }
}
