import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

export default class extends Controller{
  static targets = ['name']

  activate(e){
    e.preventDefault()
    e.currentTarget.classList.toggle("active")
    if(this.data.has('activeCssClass')){
      let cssClasses = this.data.get('activeCssClass').split(".");
      cssClasses.forEach(cssClass => {
        e.currentTarget.classList.toggle(cssClass)
      })
    }

    if(this.data.has('restingCssClass')){
      let cssClasses = this.data.get('restingCssClass').split(".");
      cssClasses.forEach(cssClass => {
        e.currentTarget.classList.toggle(cssClass)
      })
    }
    e.currentTarget.nextElementSibling.classList.toggle("hidden")

    PubSub.publish(EVENTS.ACCORDION.ACTIVATED)
  }

  updateName(e){
    e.preventDefault()
    this.element.querySelectorAll(`[data-name-for="${e.currentTarget.dataset.namedBy}"]`)[0].innerHTML = e.currentTarget.value
  }
}
