import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = [
    "subdomain",
    "academyName",
    "subdomainLoadingContainer",
    "subdomainStatusContainer",
    "subdomainStatusAvailable",
    "subdomainStatusAvailableMessage",
    "subdomainStatusUnavailable",
    "subdomainStatusUnavailableMessage",
    "subdomainRecommendations",
    "subdomainRecommendationsContainer",
    "appCategory",
    "createAcademyBtn"
  ];

  initialize(){
    this.isSubdomainValid = false
  }

  connect() {
    this.validateSubdomainUrl = `/api/setup/subdomain-validation.json`
    this._updateLoadingStatus('idal')
    this.subdomainStatusContainerTarget.classList.add("hidden")
  }

  disconnect(){}

  autoCompleteSubdomain(event) {
    event.preventDefault();
    this.subdomainTarget.value = this._stringParameterize(this.academyNameTarget.value)
    this.subdomainStatusContainerTarget.classList.add("hidden")
  }

  _stringParameterize(name) {
    return name.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
  }

  validateSubdomain() {
    if(this._isSubdomainEmpty()) {
      return
    }

    this._updateLoadingStatus('loading');
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url:  this.validateSubdomainUrl,
      method: "POST",
      data: {subdomain: this.subdomainTarget.value},
      headers:{ 'X-CSRF-Token': CSRF_TOKEN },
      success: function(data, status, xhr) {
        this._setSubdomainStatus(data)
        this._updateLoadingStatus('idal');
        deferred.resolve(data, status, xhr);
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._updateLoadingStatus('idal');
        deferred.reject(jqXHR, status, error);
      }.bind(this)
    });

    return promise;
  }

  _isSubdomainEmpty() {
    return this.subdomainTarget.value === null || this.subdomainTarget.value.match(/^ *$/) !== null
  }

  _updateLoadingStatus(status) {
    if (status == 'idal') {
      this.subdomainLoadingContainerTarget.classList.toggle('hidden', true)
    } else if (status == 'loading') {
      this.subdomainStatusContainerTarget.classList.toggle('hidden', true)
      this.subdomainLoadingContainerTarget.classList.toggle('hidden', false)
    }
  }

  _setSubdomainStatus(data) {
    this.isSubdomainValid = false
    this.subdomainStatusContainerTarget.classList.toggle('hidden', false)
    this.subdomainRecommendationsContainerTarget.classList.toggle('hidden', true)

    if (data["status"] == "available") {
      this.subdomainStatusAvailableTarget.classList.toggle('hidden', false)
      this.subdomainStatusUnavailableTarget.classList.toggle('hidden', true)
      this.subdomainStatusAvailableMessageTarget.innerHTML = data["message"]
      this.isSubdomainValid = true
    } else if (data["status"] == "unavailable") {
      this.subdomainStatusAvailableTarget.classList.toggle('hidden', true)
      this.subdomainStatusUnavailableTarget.classList.toggle('hidden', false)
      this.subdomainStatusUnavailableMessageTarget.innerHTML = data["message"]

      if (data["recommendations"].length > 0) {
        this.subdomainRecommendationsContainerTarget.classList.toggle('hidden', false)
        this.subdomainRecommendationsTarget.innerHTML = null
        data["recommendations"].forEach((el, i) => {
          this.buttonHTMLElement = "<li><button class='text-blue-600' data-action='click->onboarding--enter-academy-details#selectSuggestion'>"+el+"</button></li>"
          this.subdomainRecommendationsTarget.innerHTML += this.buttonHTMLElement
        })
      }
    }
    this.toggleCreateAcademyVisibility()
  }

  selectSuggestion(event) {
    event.preventDefault()

    if (this.element.contains(event.target)) {
      this.subdomainTarget.value = event.target.innerText
      this.subdomainRecommendationsContainerTarget.classList.toggle('hidden', true)
      this.subdomainStatusAvailableTarget.classList.toggle('hidden', false)
      this.subdomainStatusUnavailableTarget.classList.toggle('hidden', true)
      this.isSubdomainValid = true
    }
  }

  toggleCreateAcademyVisibility() {
    if(this.isSubdomainValid &&
      this.appCategoryTarget != undefined &&
      this.appCategoryTarget.value != '' &&
      this.academyNameTarget != undefined &&
      this.academyNameTarget.value != '') {
      this._enableCreateAccountButton()
    } else {
      this._disableCreateAccountButton()
    }
  }

  _disableCreateAccountButton() {
    this.createAcademyBtnTarget.disabled = true
    this.createAcademyBtnTarget.classList.add("cursor-not-allowed")
    this.createAcademyBtnTarget.setAttribute("style", "background-color: #dbe2ea;");
    this.createAcademyBtnTarget.classList.remove("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  _enableCreateAccountButton() {
    this.createAcademyBtnTarget.disabled = false
    this.createAcademyBtnTarget.classList.remove("cursor-not-allowed")
    this.createAcademyBtnTarget.removeAttribute("style")
    this.createAcademyBtnTarget.classList.add("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  trackFbGtmEventsForNextButtonClick() {
    FbGtmTracker.trackOnboardingEvent('TMO_ONBOARDING_CREATE_ACADEMY_NEXT_CLICKED', {});
  }
}
