import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = ["category", "sortableSection", "sortMethodConfirmation"]

  initialize(){}

  connect(){
    this.updateOrderUrl = this.data.get('updateOrderUrl')
    this.customModeUrl = this.data.get('customModeUrl')
    this.sortMethod = this.data.get('sortMethod')
    this.appId = this.data.get('appId')
    this.sortableSectionTargets.forEach((sortableSection, index) => {
      new Sortable(sortableSection, {
        handle: '.handle',
        group: 'nested',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onChange: function(/**Event*/evt) {
          document.getElementById('custom-btn').classList.remove('bg-gray-200', 'text-gray-500', 'hover:text-gray-700')
          document.getElementById('custom-btn').classList.add('bg-white', 'text-gray-700')
          document.getElementById('alphabetical-btn').classList.remove('bg-white', 'text-gray-700')
          document.getElementById('alphabetical-btn').classList.add('bg-gray-200', 'text-gray-500', 'hover:text-gray-700')
          this.sortMethod = 'custom'
        }.bind(this)
      });
    })
  }

  disconnect(){}

  updateCategorySortOrder(){
    this.sortedIds = []
    this.sortedParentIds = []

    this.parentIdsCounter = []
    this.categoryWithIndex = []
    this.categoryTargets.forEach((category, index) => {
      // if parent category then we'll get null value so assign them to 0 index
      if(!category.parentNode.parentNode.dataset["categoryId"]){
        category.parentNode.parentNode.dataset["categoryId"] = 0
      }
      // set counter parent id as we get so we can use that counter to increment and use them as a index for category
      if(this.parentIdsCounter[category.parentNode.parentNode.dataset["categoryId"]] == undefined){
        // set to 1 if key for particular parent category is not assigned
        this.parentIdsCounter[category.parentNode.parentNode.dataset["categoryId"]] = 1
      } else{
        // otherwise increment counter
        this.parentIdsCounter[category.parentNode.parentNode.dataset["categoryId"]]++
      }
      // assign index/counter we get from above code to assign to category
      if(!isNaN(parseInt(category.dataset["categoryId"]))){
        this.categoryWithIndex.push({
          categoryId: parseInt(category.dataset["categoryId"]),
          parentCategoryId: parseInt(category.parentNode.parentNode.dataset["categoryId"]),
          index: this.parentIdsCounter[category.parentNode.parentNode.dataset["categoryId"]]
        })
      }
    })
    console.log(this.categoryWithIndex)

    $.ajax({
      type: "PUT",
      url: this.updateOrderUrl,
      data: {"app_id": this.appId, "sorted_ids": this.categoryWithIndex, "sort_method": this.sortMethod},
      success: function(_data) {
        console.log("sorted!!")
        window.location.href = _data.redirect_url;
      }.bind(this),
      error: function(_data) {
        alert(_data.responseJSON["error_message"])
      }.bind(this)
    })
  }

  openSortConfirmationModal(){
    this.sortMethodConfirmationTarget.classList.remove("hidden")
  }

  closeSortConfirmationModal(){
    this.sortMethodConfirmationTarget.classList.add("hidden")
  }

  mouseOnLi(event) {
    if(event.currentTarget.querySelector(".view-details") != null){
      event.currentTarget.querySelector(".view-details").classList.remove("hidden")
    }
    event.currentTarget.querySelector(".product-count").classList.add("hidden")
  }

  mouseOutLi(event) {
    if(event.currentTarget.querySelector(".view-details") != null){
      event.currentTarget.querySelector(".view-details").classList.add("hidden")
    }
    event.currentTarget.querySelector(".product-count").classList.remove("hidden")
  }

  showCollapseAccordion(event) {
    console.log(event.currentTarget.id)
    if(event.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelector("#child-"+event.currentTarget.id)){
      event.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelector("#child-"+event.currentTarget.id).classList.toggle("show")
    }
    event.currentTarget.querySelector("#chevron-up").classList.toggle("hidden")
    event.currentTarget.querySelector("#chevron-down").classList.toggle("hidden")
  }

  collapseAll(event){
    this.sortableSectionTargets.forEach(function(sortableSection) {
      sortableSection.classList.remove("show")
    });
    event.currentTarget.classList.toggle("hidden")
    event.currentTarget.nextElementSibling.classList.toggle("hidden")
  }

  expandAll(event){
    this.sortableSectionTargets.forEach(function(sortableSection) {
      sortableSection.classList.add("show")
    });
    event.currentTarget.classList.toggle("hidden")
    event.currentTarget.previousElementSibling.classList.toggle("hidden")
  }

}
