import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  initialize(){}

  connect(){
    this.appUid = this.data.get('appUid');
  }

  disconnect(){}

  toggleCollectionFeatured(event) {
    const element = event.target
    let collectionId = element.value

    var fd = new FormData();
    fd.append("featured", element.checked);

    Rails.ajax({
      type: "PUT",
      url: `/admin/content/collections/${collectionId}/set-featured`,
      data: fd,
      success: function(_data) {}.bind(this),
      error: function(_data) {
        element.checked = !element.checked
        alert("Failed to set featured")
      }.bind(this)
    })
  }
}
