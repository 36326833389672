import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    // Live video index
    "goLiveInMultipleCoursesBtn",

    // Add Live video to other courses modal
    "liveVideoCoursesLoading",
    "otherCoursesSelectionTable",
    "otherCourseListContainer",
    "liveVideoAlreadyAddedContainer",
    "otherCoursesListSortBtn",
    "addLiveVideoContainer",
    "otherCoursesSearchTextfield",
    "addLiveVideoToOtherCoursesModal",
    "addToCourseErrorContainer",
    "addToCourseErrorDisplayText",

    // Added live video courses list modal
    "addedLiveClassCoursesListModal",
    "addedLiveClassCoursesListLoading",
    "addedLiveClassCoursesListTable",
    "addedLiveClassCoursesListContainer",
    "addedLiveClassCoursesGoLiveBtn"
  ]

  initialize() {}

  connect() {}

  disconnect() {}

  openAddLiveClassToOtherCoursesModal({ params }) {
    this.sharedLiveVideoId = params.sharedLiveVideoId
    this.sharedLiveVideoUid = params.sharedLiveVideoUid
    this.addLiveVideoToOtherCoursesModalTarget.classList.remove("hidden")
    this._loadCourses()
  }

  _loadCourses() {
    var sortOrder = this.otherCoursesListSortBtnTarget.value
    this.liveVideoCoursesLoadingTarget.classList.remove("hidden")
    this.otherCoursesSearchTextfieldTarget.value = ""
    this.getSearchedCourses("", sortOrder)
  }

  getSearchedCourses(query, sortOrder) {
    this.otherCourseListContainerTarget.innerHTML = ""
    this.otherCoursesSelectionTableTarget.classList.add("hidden")
    $.ajax({
      url: `/api/web/admin/live_class_and_attendance/shared_live_videos/${this.sharedLiveVideoId}/courses`,
      method: "GET",
      data: { query: query, sort: sortOrder },
      success: function(response){
        this.liveVideoCoursesLoadingTarget.classList.add("hidden")
        this.otherCourseListContainerTarget.innerHTML = JSON.parse(response)
        this.otherCoursesSelectionTableTarget.classList.remove("hidden")
      }.bind(this)
    })
  }

  publishUnpublishLiveVideo({ params }) {
    this._addLiveVideoToCourse(params.selectedCourseId, params.publishingStatus)
  }

  _addLiveVideoToCourse(courseId, publishing_status) {
    $.ajax({
      url: `/api/web/admin/live_class_and_attendance/shared_live_videos/${this.sharedLiveVideoId}/add-to-course`,
      method: "PUT",
      data: { course_id: courseId, publishing_status: publishing_status },
      success: function(response){
        this.hideErrorMessage(courseId)
        $(`a[id='join-live-btn'][data-live-video-id='${this.sharedLiveVideoId}']`).addClass('hidden')
        this.changeGoLiveInMultipleCoursesText(response.added_live_class_courses_count)
        $(`div[data-live-class-and-attendance--shared-live-videos-target='liveVideoAlreadyAddedContainer'][data-course-id='${courseId}']`).removeClass("hidden")
        $(`div[data-live-class-and-attendance--shared-live-videos-target='addLiveVideoContainer'][data-course-id='${courseId}']`).addClass("hidden")
      }.bind(this),
      error: function(xhr, error) {
        this.showErrorMessage(courseId, xhr.responseJSON.error_message)
      }.bind(this)
    });
  }

  deleteLiveClassFromCourse({ params }) {
    var courseId = params.selectedCourseId
    $.ajax({
      url: `/api/web/admin/live_class_and_attendance/shared_live_videos/${this.sharedLiveVideoId}/delete-from-course`,
      method: "DELETE",
      data: { course_id: courseId },
      success: function(response){
        this.hideErrorMessage(courseId)
        $(`div[data-live-class-and-attendance--shared-live-videos-target='liveVideoAlreadyAddedContainer'][data-course-id='${courseId}']`).addClass("hidden")
        $(`div[data-live-class-and-attendance--shared-live-videos-target='addLiveVideoContainer'][data-course-id='${courseId}']`).removeClass("hidden")

        if(response.added_live_class_courses_count > 1) {
          this.changeGoLiveInMultipleCoursesText(response.added_live_class_courses_count)
          $(`a[id='join-live-btn'][data-live-video-id='${this.sharedLiveVideoId}']`).addClass('hidden')
        } else {
          $(`button[data-live-class-and-attendance--shared-live-videos-target='goLiveInMultipleCoursesBtn'][data-live-video-id='${this.sharedLiveVideoId}']`).addClass('hidden')
          $(`a[id='join-live-btn'][data-live-video-id='${this.sharedLiveVideoId}']`).removeClass('hidden')
        }
      }.bind(this),
      error: function(xhr, error) {
        this.showErrorMessage(courseId, xhr.responseJSON.error_message)
      }.bind(this)
    })
  }

  showErrorMessage(courseId, errorMessage) {
    var errorContainer = $(`span[data-live-class-and-attendance--shared-live-videos-target='addToCourseErrorContainer'][data-course-id='${courseId}']`)
    errorContainer.removeClass("hidden")
    errorContainer.html(errorMessage)
  }

  hideErrorMessage(courseId) {
    $(`span[data-live-class-and-attendance--shared-live-videos-target='addToCourseErrorContainer'][data-course-id='${courseId}']`).addClass("hidden")
  }

  changeGoLiveInMultipleCoursesText(added_live_class_courses_count) {
    var goLiveInMultipleCoursesBtn = $(`button[data-live-class-and-attendance--shared-live-videos-target='goLiveInMultipleCoursesBtn'][data-live-video-id='${this.sharedLiveVideoId}']`)
    goLiveInMultipleCoursesBtn.removeClass('hidden')
    goLiveInMultipleCoursesBtn.html(`Go live in ${added_live_class_courses_count} courses`)
  }

  searchCourses() {
    this.liveVideoCoursesLoadingTarget.classList.remove("hidden")
    var sortOrder = this.otherCoursesListSortBtnTarget.value
    this.getSearchedCourses(this.otherCoursesSearchTextfieldTarget.value, sortOrder)
  }

  sortCourses() {
    var sortOrder = this.otherCoursesListSortBtnTarget.value == 'ASC' ? 'DESC' : 'ASC'
    this.otherCoursesListSortBtnTarget.value = sortOrder
    this.liveVideoCoursesLoadingTarget.classList.remove("hidden")
    this.getSearchedCourses(this.otherCoursesSearchTextfieldTarget.value, sortOrder)
  }

  // Go live in multiple courses

  openAddedLiveVideoCoursesListModal({ params }) {
    this.sharedLiveVideoId = params.sharedLiveVideoId
    this.sharedLiveVideoUid = params.sharedLiveVideoUid
    this.addedLiveClassCoursesListModalTarget.classList.remove("hidden")
    this.addedLiveClassCoursesListTableTarget.classList.add("hidden")
    this.addedLiveClassCoursesListContainerTarget.innerHTML = ""
    this.addedLiveClassCoursesListLoadingTarget.classList.remove("hidden")
    this.getAddedLiveClassCourses()
  }

  getAddedLiveClassCourses(){
    $.ajax({
      url: `/api/web/admin/live_class_and_attendance/shared_live_videos/${this.sharedLiveVideoId}/added-courses`,
      method: "GET",
      data: {},
      success: function(response){
        this.addedLiveClassCoursesListLoadingTarget.classList.add("hidden")
        this.addedLiveClassCoursesListContainerTarget.innerHTML = JSON.parse(response)
        this.addedLiveClassCoursesListTableTarget.classList.remove("hidden")
        this.addedLiveClassCoursesGoLiveBtnTarget.href = `/admin/live/${this.sharedLiveVideoUid}/start?btn_src=join_live_clicked`
      }.bind(this)
    })
  }
}
