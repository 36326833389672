import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "nameTextfield",
    "emailTextfield",
    "roleButton",
    "confirmButton",
    "selectedRolesOuterContainer",
    "selectedRoleButtonsContainer",
    "inviteAdminValidationErrorsContainer",
    "inviteAdminValidationErrorMessage",
    "emailSentMessage",
    "emailSentTitle"
  ]

  initialize() {
    this.selectedRoleIds = []
  }

  connect() {}

  openInviteAdminModal(event){
    $("#invite-admin-modal").removeClass("hidden")
    $("#invite-admin-modal").css("display", "flex")
    $("#new-invite-container").removeClass("hidden")
    $("#footer-new-invite-confirmation-button-container").removeClass("hidden")
    $("#email-sent-container").addClass("hidden")

    console.log(event.currentTarget.dataset);
  }

  closeAdminInviteModal() {
    $("#invite-admin-modal").addClass("hidden")
    $("#invite-admin-modal").css("display", "none")
    $("#new-invite-container").addClass("hidden")
    $("#footer-new-invite-confirmation-button-container").addClass("hidden")
    $("#email-sent-container").addClass("hidden")
    this.nameTextfieldTarget.value = ""
    this.emailTextfieldTarget.value = ""
    this._removeSelectedRolesOnCloseModal()
  }

  confirmButtonClicked(event) {
    event.preventDefault();

    this.showLoadingSymbol()

    if(this._isAnyFieldInvalid()) {
      this.hideLoadingSymbol()
      this.showErrorOnConfirmation()
      return
    }

    $.ajax({
      type: 'post',
      url: '/api/web/admin/people/admins/create-invite-and-notify',
      data: {
        name: this.nameTextfieldTarget.value,
        email: this.emailTextfieldTarget.value,
        role_ids: this.selectedRoleIds
       },
      success: function(response) {
        this.hideLoadingSymbol()
        this.showEmailMessageContainer(response.title, response.message)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.showEmailMessageContainer(xhr.responseJSON.error_title, xhr.responseJSON.error_message)
        this.hideLoadingSymbol()
      }.bind(this)
    })
  }

  showEmailMessageContainer(title, message) {
    $("#new-invite-container").addClass("hidden")
    $("#email-sent-container").removeClass("hidden")
    this.emailSentTitleTarget.innerHTML = title
    this.emailSentMessageTarget.innerHTML = message
    $("#footer-new-invite-confirmation-button-container").addClass("hidden")
  }

  selectRole(event) {
    this.selectedRolesOuterContainerTarget.classList.remove("hidden")
    let selectedRole = JSON.parse(event.target.dataset.selectedRole)

    this.roleButtonTargets.forEach((target, index) => {
      let role = JSON.parse(target.dataset.selectedRole)
      if(role.id == selectedRole.id) {
        this.selectedRoleIds.push(role.id)
        this._createAndAddSelectedRoleElementsInContainer(role)
        target.classList.add("hidden")
        return
      }
    });
  }

  _createAndAddSelectedRoleElementsInContainer(role) {
    var selectedRoleContainer = document.createElement("div");
    selectedRoleContainer.classList.add("btn", "border", "border-gray-200", "bg-gray-50", "text-gray-600");
    selectedRoleContainer.setAttribute("id", `selected_role_button_id_${role.id}`);
    selectedRoleContainer.dataset.action = 'click->people--admins--invite#removeSelectedRole'
    selectedRoleContainer.setAttribute("data-people--admins--invite-selected-role-id-param", `${role.id}`)
    selectedRoleContainer.innerHTML = `<span class="fa fa-check -ml-0.5 mr-2 h-4 w-4 text-primary-500"></span> ${role.name}`

    var removeBtn = document.createElement("button");
    removeBtn.classList.add("ml-2", "text-gray-600", "flex", "items-center", "focus:outline-none")
    removeBtn.innerHTML = '<i class="fa fa-times mt-0.5" aria-hidden="true"></i>'
    removeBtn.dataset.action = 'click->people--admins--invite#removeSelectedRole'
    removeBtn.setAttribute("data-people--admins--invite-selected-role-id-param", `${role.id}`)
    selectedRoleContainer.appendChild(removeBtn)
    this.selectedRoleButtonsContainerTarget.appendChild(selectedRoleContainer);
  }

  removeSelectedRole({ params }) {
    let selectedRoleId = params.selectedRoleId
    this._removeSelectedRoleFromListAndSelectedRoleIDs(selectedRoleId)
  }

  _addRoleBackToList(selectedRoleId) {
    this.roleButtonTargets.forEach((target, index) => {
      let role = JSON.parse(target.dataset.selectedRole)
      if(role.id == selectedRoleId) {
        target.classList.remove("hidden")
      }
    })
  }

  showErrorOnConfirmation() {
    this.inviteAdminValidationErrorsContainerTarget.classList.remove("hidden")
    if(this.nameTextfieldTarget.value == null || this.nameTextfieldTarget.value == "") {
      this.inviteAdminValidationErrorMessageTarget.innerHTML = "Name should not be blank"
    } else if(this.emailTextfieldTarget.value == null || this.emailTextfieldTarget.value == "") {
      this.inviteAdminValidationErrorMessageTarget.innerHTML = "Email should not be blank"
    } else if(this.selectedRoleIds.length == 0) {
      this.inviteAdminValidationErrorMessageTarget.innerHTML = "Please select atleast one role"
    }
  }

  showLoadingSymbol() {
    $("#invite-admin-confirmation-loading-symbol").removeClass("hidden");
    this.inviteAdminValidationErrorsContainerTarget.classList.add("hidden")
    this.confirmButtonTarget.classList.add("hidden")
  }

  hideLoadingSymbol() {
    $("#invite-admin-confirmation-loading-symbol").addClass("hidden");
    this.confirmButtonTarget.classList.remove("hidden")
  }

  _isAnyFieldInvalid() {
    return this.nameTextfieldTarget.value == null ||
           this.nameTextfieldTarget.value == "" ||
           this.emailTextfieldTarget.value == null ||
           this.emailTextfieldTarget.value == "" ||
           this.selectedRoleIds.length == 0
  }

  hideInviteAdminValidationErrorsContainer() {
    this.inviteAdminValidationErrorsContainerTarget.classList.add("hidden")
  }

  _removeSelectedRolesOnCloseModal() {
    this.roleButtonTargets.forEach((target, index) => {
      let role = JSON.parse(target.dataset.selectedRole)
      if(this.selectedRoleIds.includes(role.id)) {
        this._removeSelectedRoleFromListAndSelectedRoleIDs(role.id)
      }
    })
  }

  _removeSelectedRoleFromListAndSelectedRoleIDs(selectedRoleId) {
    var index = this.selectedRoleIds.indexOf(selectedRoleId)
    this.selectedRoleIds.splice(index, 1)
    this._addRoleBackToList(selectedRoleId)
    var roleButton = document.getElementById("selected_role_button_id_"+selectedRoleId)
    roleButton.parentNode.removeChild(roleButton);
    if(this.selectedRoleIds.length == 0) {
      this.selectedRolesOuterContainerTarget.classList.add("hidden")
    }
  }
}
