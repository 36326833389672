import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import { PubSub } from 'pubsub-js'
import { unsubscribeTokens } from 'shared/utils/utils'
import consumer from 'channels/consumer'


export default class extends Controller {
  static targets = [
    "loadingIndicator",
    "removingIndicatore",
    "uploadingProgressIndicator",
    "progressBar",
    "uploaderContainer",
    "previewImagePlaceholder",
    "previewImageContainer",
    "previewImage",
    "errorMessage",
    "actionButtonsContainer",
    "downloadLinkButton",
    "uploadFileInput",
    "changeFileInput"
  ]
  initialize(){}

  connect(){
    this.widget = { status: 'loading', progress: 0, errorMessage: '' }
    this.sharedImage = { uid: this.data.get("sharedImageUid") }
    this.uploadRequestData = {}
    this.uiParams = this._getUiParams()
    this.imageName = this.data.get('imageName')
    this.appId = this.data.get("appId")
    this.maxFileSizeInBytes = this.data.get('maxFileSizeInBytes')
    this.allowedFileMimes = JSON.parse(this.data.get('allowedFileMimes'))
    this.apiBaseUrl = `/api/web/admin/shared-images/${this.sharedImage.uid}`
    this.uploadRequestDataUrl = `${this.apiBaseUrl}/upload-request-data.json`
    this.imageUploadedUrl = `${this.apiBaseUrl}/image-uploaded.json`
    this.deleteImageUrl = `${this.apiBaseUrl}/delete-file.json`
    this.loadImageUrl = `${this.apiBaseUrl}/details.json`

    this._loadSharedImage().then(
      function(data, status, xhr){
        this._updateWidget({ status: 'idal', progress: 0, errorMessage: '' })
      }.bind(this),
      function(jqXHR, status, error) {
        console.log(error);
        this._updateWidget({status: 'idal', progress: 0, errorMessage: 'Failed to load image'})
      }.bind(this)
    )
  }

  uploadImage(e){
    this.file = e.target.files[0]
    if (!this._validateImageFile()){
      return
    }
    this._updateWidget({ status: 'uploading', progress: 0, errorMessage: '' })
    this._uploadRequestData().then(
      function(uploadRequestData, status, xhr){
        // console.log(uploadRequestData)
        this.uploadRequestData = uploadRequestData
        this._loadSharedImage().then(
          function(data, status, xhr){
            this._uploadFileToS3();
          }.bind(this),
          function(jqXHR, status, error) {
            console.log(error);
            this._updateWidget({status: 'idal', progress: 0, errorMessage: 'Failed to load image'})
          }.bind(this)
        )
      }.bind(this),
      function(jqXHR, status, error) {
        console.log(error);
        this._updateWidget({status: 'idal', progress: 0, errorMessage: 'Failed to upload image'})
      }.bind(this)
    )
  }

  deleteImage(){
    if (!window.confirm("Are you sure you want to delete this image?")) { return }

    this._updateWidget({status: 'removing', progress: 0, errorMessage: ''})
    $.ajax({
      url: this.deleteImageUrl,
      method: "DELETE",
      data: this.uiParams,//{ page_id: this.pageId, page_section_id: this.pageSectionId },
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        this.sharedImage = data
        this._updateWidget({status: 'idal', progress: 0, errorMessage: ''})
        if(this.imageName == 'Icon') {
          this._updateFaviconPreview()
        } else {
          this._refreshPreview()
        }
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._updateWidget({status: 'idal', progress: 0, errorMessage: 'Failed to delete image.'})
      }.bind(this)
    });
  }

  _updateWidget(details){
    this.widget = details

    if (this.widget.errorMessage != null && this.widget.errorMessage != ''){
      this.errorMessageTarget.innerHTML = this.widget.errorMessage
      this.errorMessageTarget.classList.remove('hidden')
    } else {
      this.errorMessageTarget.classList.add('hidden')
    }

    if (this.widget.status == 'uploading'){ // uploading new image
      console.log(`progress: ${this.widget.progress}`)
      // Show uploading progress
      this.uploadingProgressIndicatorTarget.classList.remove('hidden')
      this.progressBarTarget.style.width = `${this.widget.progress}%`

      this.previewImagePlaceholderTarget.classList.remove('hidden')

      this.previewImageContainerTarget.classList.add('hidden')
      this.actionButtonsContainerTarget.classList.add('hidden')
      this.loadingIndicatorTarget.classList.add('hidden')
      this.uploaderContainerTarget.classList.add('hidden')
      this.removingIndicatoreTarget.classList.add('hidden')

    } else if (this.widget.status == 'loading'){ // Loading sharedImage details
      // Show LoadingIndicatior
      this.loadingIndicatorTarget.classList.remove('hidden')
      this.previewImagePlaceholderTarget.classList.remove('hidden')

      this.uploaderContainerTarget.classList.add('hidden')
      this.actionButtonsContainerTarget.classList.add('hidden')
      this.previewImageContainerTarget.classList.add('hidden')
      this.removingIndicatoreTarget.classList.add('hidden')
      this.uploadingProgressIndicatorTarget.classList.add('hidden')

    } else if (this.widget.status == 'removing'){ // Deleting uploaded image
      // Show RemovingIndicator
      this.removingIndicatoreTarget.classList.remove('hidden')
      this.previewImagePlaceholderTarget.classList.remove('hidden')

      this.previewImageContainerTarget.classList.add('hidden')
      this.actionButtonsContainerTarget.classList.add('hidden')
      this.loadingIndicatorTarget.classList.add('hidden')
      this.uploaderContainerTarget.classList.add('hidden')
      this.uploadingProgressIndicatorTarget.classList.add('hidden')

    } else if (this.widget.status == 'idal' && this.sharedImage.original == null){ // Image is not uploaded
      // Show uploader
      this.uploaderContainerTarget.classList.remove('hidden')

      this.previewImagePlaceholderTarget.classList.remove('hidden')

      this.loadingIndicatorTarget.classList.add('hidden')
      this.actionButtonsContainerTarget.classList.add('hidden')
      this.previewImageContainerTarget.classList.add('hidden')
      this.removingIndicatoreTarget.classList.add('hidden')
      this.uploadingProgressIndicatorTarget.classList.add('hidden')

      this.uploadFileInputTarget.value = ""
      this.changeFileInputTarget.value = ""

    } else if (this.widget.status == 'idal' && this.sharedImage.original != null){ // Image is uploaded

      // Show ImagePreview
      this.previewImageContainerTarget.classList.remove('hidden')
      this.previewImageTarget.setAttribute("src", this.sharedImage.previewImageUrl)

      // Show ActionButtons
      this.actionButtonsContainerTarget.classList.remove('hidden')
      // this.downloadLinkButtonTarget.setAttribute("href", this.sharedImage.downloadUrl)

      this.previewImagePlaceholderTarget.classList.add('hidden')
      this.loadingIndicatorTarget.classList.add('hidden')
      this.uploaderContainerTarget.classList.add('hidden')
      this.removingIndicatoreTarget.classList.add('hidden')
      this.uploadingProgressIndicatorTarget.classList.add('hidden')

      this.uploadFileInputTarget.value = ""
      this.changeFileInputTarget.value = ""
    }
  }

  _loadSharedImage(){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url:  this.loadImageUrl,
      method: "GET",
      data: this.uiParams, //{ page_id: this.pageId, page_section_id: this.pageSectionId },
      headers:{ 'X-CSRF-Token': CSRF_TOKEN },
      success: function(data, status, xhr) {
        this.sharedImage = data
        deferred.resolve(data, status, xhr);
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._updateWidget({ status: 'idal', progress: 0, errorMessage: error });
        deferred.reject(jqXHR, status, error);
      }.bind(this)
    });

    return promise;
  }

  _validateImageFile(){
    if (this.file.size > this.maxFileSizeInBytes){
      this._updateWidget({status: 'idal', progress: 0, errorMessage: `Maximum file size allowed is ${this.sharedImage.maxFileSizeInputValue}`});
      return false;
    } else if (!this.allowedFileMimes.includes(this.file.type)){
      this._updateWidget({status: 'idal', progress: 0, errorMessage: `Please upload a valid file (${this.allowedFileMimes.join(', ').replaceAll('image/', '')})`});
      return false;
    } else {
      return true;
    }
  }

  _uploadRequestData(){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: this.uploadRequestDataUrl,
      method: "POST",
      data: Object.assign(
        {
          // page_id: this.pageId,
          // page_section_id: this.pageSectionId,
          filename: this.file.name,
          file_size: this.file.size,
          file_type: this.file.type
        },
        this.uiParams
      ),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }

  _uploadFileToS3(){
    var form_data = new FormData();
    var data_object = Object.assign(this.uploadRequestData.data, { file: this.file });
    var field_name;
    for(field_name in data_object) {
      form_data.append(field_name, data_object[field_name])
    }
    $.ajax({
      url: this.uploadRequestData.url,
      method: "POST",
      contentType: false,
      processData: false,
      data: form_data,
      xhr: function(){
        // get the native XmlHttpRequest object
        var xhr = $.ajaxSettings.xhr() ;
        // set the onprogress event handler
        xhr.upload.onprogress = function(evt){ this._updateWidget({ status: 'uploading', progress: evt.loaded/evt.total*100, errorMessage: '' }) }.bind(this);
        // set the onload event handler
        xhr.upload.onload = function(){ console.log('DONE!') } ;
        // return the customized object
        return xhr ;
      }.bind(this),
      success: function(data, status, xhr) {
        this._fileUploadToS3Completed();
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._updateWidget({status: 'idal', progress: 0, errorMessage: 'Failed to upload image.'})
      }.bind(this)
    });

  }

  _fileUploadToS3Completed() {
    $.ajax({
      url: this.imageUploadedUrl,
      method: "PUT",
      data: Object.assign(
        {
          // page_id: this.pageId,
          // page_section_id: this.pageSectionId,
          shared_image_uid: this.sharedImage.uid,
          filename: this.file.name,
          version: this.uploadRequestData.version,
          file_size: this.file.size,
          file_type: this.file.type
        },
        this.uiParams
      ),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        this.sharedImage = data
        this._updateWidget({status: 'idal', progress: 0, errorMessage: ''})
        if(this.imageName == 'Icon') {
          this._updateFaviconPreview()
        } else {
          this._refreshPreview()
        }
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
        this._updateWidget({status: 'idal', progress: 0, errorMessage: error})
      }.bind(this)
    });
  }

  _updateFaviconPreview(){
    if(!(this.imageName == 'Icon')){
      return
    }

    if(this.widget.status == 'idal' && this.sharedImage.original != null){
      document.getElementById("favicon_preview").setAttribute("src", this.sharedImage.previewImageUrl)
      document.getElementById("favicon_preview").classList.remove('hidden')
    } else {
      document.getElementById("favicon_preview").setAttribute("src", this.sharedImage.previewImageUrl)
      document.getElementById("favicon_preview").classList.add('hidden')
    }
  }

  _refreshPreview(){
    console.log("refreshingPreview")
    var iframe = document.getElementById('preview');
    document.getElementById('favicon_preview').style.display = 'none';
    document.getElementById('preview_loading_indicator').style.display = 'block';
    iframe.src = iframe.src;
  }

  _getUiParams(){
    let data = {}

    if(this.data.has("uiParams")) {
      let params = JSON.parse(this.data.get("uiParams"))
      for(var key in params){
        data[key] = params[key]
       }
    }

    return data
  }
}
