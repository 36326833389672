export class TmoEvents {
  static logAsync(eventName, eventMetadata) {
    $.ajax({
      url: '/api/web/admin/tmo_events/log_async',
      method: "post",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      data:{ tmo_event: { name: eventName, metadata: eventMetadata } },
      success: function(data) {
        // console.log("event logged");
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }
}
