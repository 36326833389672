import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = ["categoryProduct", "sortableSection"]

  initialize(){}

  connect(){
    this.updateOrderUrl = this.data.get('updateOrderUrl')
    this.getSubcategoriesUrl = this.data.get('getSubcategoriesUrl')
    this.appId = this.data.get('appId')
    this.sortMethod = this.data.get('sortMethod')
    this.sortable = Sortable.create(this.sortableSectionTarget,{
      handle: '.handle',
      onChange: function(/**Event*/evt) {
        document.getElementById('product-custom-btn').classList.remove('bg-gray-200', 'text-gray-500', 'hover:text-gray-700')
        document.getElementById('product-custom-btn').classList.add('bg-white', 'text-gray-700')
        document.getElementById('product-alphabetical-btn').classList.remove('bg-white', 'text-gray-700')
        document.getElementById('product-alphabetical-btn').classList.add('bg-gray-200', 'text-gray-500', 'hover:text-gray-700')
        document.getElementById('product-collection-first-btn').classList.remove('bg-white', 'text-gray-700')
        document.getElementById('product-collection-first-btn').classList.add('bg-gray-200', 'text-gray-500', 'hover:text-gray-700')
        this.sortMethod = 'custom'
      }.bind(this)
    })
    this.sortSuccessAlert.classList.add('hidden')
  }

  disconnect(){}

  updateCategoryProductSortOrder(event){
    this.sortedIds = []
    this.categoryProductTargets
    this.categoryProductTargets.forEach((product, index) => {
      this.sortedIds.push({"product_type": product.dataset["productType"], "product_id": product.dataset["productId"]})
    })
    $.ajax({
      type: "PUT",
      url: this.updateOrderUrl,
      data: {"app_id": this.appId, "sorted_ids": this.sortedIds, "sort_method": this.sortMethod},
      success: function(_data) {
        console.log("sorted!!")
        this.sortSuccessAlert = document.getElementById("sort-success-alert")
        this.sortSuccessAlert.classList.remove('hidden')
        document.getElementById('sort-success-alert-text').innerHTML= "Category products sorted successfully"
      }.bind(this),
      error: function(_data) {
        console.log("Failed!!")
        alert(_data.responseJSON["error_message"])
      }.bind(this)
    })
  }

  subcategoryDropdownChanged(event) {
    this.categoryId = ""
    if(event != undefined){
      this.categoryId = event.currentTarget.value
      window.location.href = "/admin/content/categories/"+this.categoryId+"/sort-category-products"
    }
  }
}
