import Rails from '@rails/ujs';
import 'bootstrap';
import Turbolinks from 'turbolinks';
import Trix from 'trix';
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false

Rails.start();
Turbolinks.start();

//autoloading stimulus controllers

const application = Application.start();

const component_context = require.context('components', true, /_controller\.js$/);
const context = require.context('./controllers', true, /\.js$/);

application.load(definitionsFromContext(component_context));
application.load(definitionsFromContext(context));

global.$ = jQuery;
// global.Teachmore = application;

var csrf_token = $('meta[name="csrf-token"]').attr('content')
global.CSRF_TOKEN = csrf_token

document.addEventListener('turbolinks:load', function() {
  feather.replace()
  //TODO do something if the image link is broken
  $('img').on('error', function() {
    // $(this).hide();
    $(this).attr('src', "https://dummyimage.com/1280x720/fff/fff.jpg&text=+");
  });

  $('#tmMainContentHolder').scroll(function(){
    var winTop = $('#tmMainContentHolder').scrollTop();
    var top = 50;
    if(winTop >= top){
      $("#headerTitle").removeClass("hidden");
      $("#topNav").removeClass("md:shadow-none");
    }else{
      $("#headerTitle").addClass("hidden");
      $("#topNav").addClass("md:shadow-none");
    }
  });
});
