import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

export default class extends Controller{
  static targets = ['content', 'link']

  activate(e){
    e.preventDefault()
    let tab = e.target.dataset.link
    this.contentTargets.forEach((target, index) => {
      if(target.dataset.name == tab){
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
    this.linkTargets.forEach((target, index) => {
      if(target.dataset.link == tab){
        target.classList.add("active");
      } else {
        target.classList.remove("active");
      }
    });

    PubSub.publish(
      EVENTS.TAB.ACTIVATED,
      {
        pageId: this.element.dataset.pageId,
        pageSectionId: this.element.dataset.pageSectionId,
        blockId: this.element.dataset.blockId,
        tabsFor: this.element.dataset.tabsFor,
        activeTabName: tab
      }
    )

  }
}
