import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "message" ]

  initialize(){}

  connect(){
    this.totalSteps = 5
    this.currentStep = 1
    this.appThemeStyleId = null
    this._executeNextStep()
  }

  disconnect(){}

  _display_message(msg){
    this.messageTarget.innerHTML = msg
  }

  _executeNextStep(){
    if (this.currentStep <= this.totalSteps) {
      this._api_call(`/api/web/admin/initial-setup/step/${this.currentStep}.json`).then(
        function(data, status, xhr){
          this._display_message(data.message)
          if(data.app_theme_style_id != undefined && data.app_theme_style_id != null && data.app_theme_style_id != ""){
            this.appThemeStyleId = data.app_theme_style_id
          }
          this.currentStep++;
          this._executeNextStep()
        }.bind(this),
        function(jqXHR, status, error) {
          console.log(error);
          alert("Something went wrong")
        }.bind(this)
      )
    } else {
      this._executeCompletedApi()
    }
  }

  _api_call(url){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: url,
      method: "POST",
      data: {app_theme_style_id:  this.appThemeStyleId},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }

  _executeCompletedApi() {
    $.ajax({
      url: "/api/web/admin/initial-setup/completed.json",
      method: "POST",
      data: {},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        window.location.href = data.redirect_url
      },
      error: function(jqXHR, status, error) {
        alert("Website setup failed")
      }
    });
  }
}
