import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = [
    "lndAdminEmailInput",
    "lndAdminFirstNameInput",
    "lndAdminLastNameInput",
    "lndAdminEmployeeIdInput",
    "callingCodeInput",
    "lndAdminMobileNumberInput",
    "jobDesignationInput",
    "baseLocationInput",
    "lndAdminJoiningDateInput",
    "assignTeamInput",
    "firstFiveTeamsContainer",
    "moreTeamsBtn",
    "moreTeamsContainer",
    "assignBatchInput",
    "moreBatchesBtn",
    "firstFiveBatchesContainer",
    "moreBatchesContainer",
    "roleButton",
    "confirmButton",
    "selectedRolesOuterContainer",
    "selectedRoleButtonsContainer",
    "lndInviteAdminValidationErrorsContainer",
    "lndInviteAdminValidationErrorMessage",
    "lndAnviteAdminPersonalDetailsValidationErrorsContainer",
    "lndAnviteAdminPersonalDetailsValidationErrorsErrorMessage",
    "emailSentMessage",
    "emailSentTitle",
    "personalDetailsForm",
    "loadingContainer"
  ]

  initialize() {
    this.selectedRoleIds = []
    this.selectedLndTeamIds = []
    this.selectedLndBatchIds = []
  }

  connect() {}

  openInviteAdminModal(event){
    $("#lnd-invite-admin-modal").removeClass("hidden")
    $("#lnd-invite-admin-modal").css("display", "flex")
    $("#lnd-admin-new-invite-container").removeClass("hidden")
    $("#lnd-admin-invite-email-sent-container").addClass("hidden")
    this._enableTagifyForTextfields()

    TmoEvents.logAsync("TLND_ADMINS_INVITE_ADMIN_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "admins_invite_admin_btn", btn_name: "Invite admin" })
    console.log(event.currentTarget.dataset);
    this._getCourses()
  }

  closeInviteAdminModal() {
    $("#lnd-invite-admin-modal").addClass("hidden")
    $("#lnd-invite-admin-modal").css("display", "none")
    $("#lnd-admin-new-invite-container").addClass("hidden")
    $("#lnd-admin-invite-email-sent-container").addClass("hidden")
    this._setPersonalDetailsTabSelected()
    this._clearForm()
    this.hideConfirmLoadingSymbol()
  }

  saveAndNext(event) {
    this.lndAnviteAdminPersonalDetailsValidationErrorsContainerTarget.classList.add("hidden")
    if(this._areAllPersonalDetailsAdded() == true) {
      $("#lnd-admin-roles-tab").addClass("active")
      $("#lnd-admin-personal-details-tab").removeClass("active")
      $("#adminRoles").addClass("active")
      $("#adminRoles").addClass("show")
      $("#adminPersonalDetails").removeClass("active")
      $("#adminPersonalDetails").removeClass("show")
    } else {
      this.lndAnviteAdminPersonalDetailsValidationErrorsContainerTarget.classList.remove("hidden")
      this.lndAnviteAdminPersonalDetailsValidationErrorsErrorMessageTarget.innerHTML = "Please fill all the mandatory fields"
    }
  }

  confirmButtonClicked(event) {
    event.preventDefault();
    TmoEvents.logAsync("TLND_ADMINS_SEND_INVITE_LINK_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "admins_send_invite_btn", btn_name: "Send invite" })

    this.showConfirmLoadingSymbol()

    if(this._isAnyFieldInvalid()) {
      this.hideConfirmLoadingSymbol()
      this.showErrorOnConfirmation()
      return
    }

    $.ajax({
      type: 'post',
      url: '/api/web/admin/user_management/admins/create-invite-and-notify',
      data: {
        email: this.lndAdminEmailInputTarget.value,
        first_name: this.lndAdminFirstNameInputTarget.value,
        last_name: this.lndAdminLastNameInputTarget.value,
        employee_id: this.lndAdminEmployeeIdInputTarget.value,
        calling_code_country_id: this.callingCodeInputTarget.value,
        mobile_number: this.lndAdminMobileNumberInputTarget.value,
        job_designation_id: this.jobDesignationInputTarget.value,
        base_location_id: this.baseLocationInputTarget.value,
        joining_date: this.lndAdminJoiningDateInputTarget.value,
        role_ids: this.selectedRoleIds,
        team_ids: this.selectedLndTeamIds,
        batch_ids: this.selectedLndBatchIds
       },
      success: function(response) {
        this.hideConfirmLoadingSymbol()
        this.showEmailMessageContainer(response.title, response.message)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.showEmailMessageContainer(xhr.responseJSON.error_title, xhr.responseJSON.error_message)
        this.hideConfirmLoadingSymbol()
      }.bind(this)
    })
  }

  showEmailMessageContainer(title, message) {
    $("#lnd-admin-new-invite-container").addClass("hidden")
    $("#lnd-admin-invite-email-sent-container").removeClass("hidden")
    this.emailSentTitleTarget.innerHTML = title
    this.emailSentMessageTarget.innerHTML = message
  }

  showErrorOnConfirmation() {
    this.lndInviteAdminValidationErrorsContainerTarget.classList.remove("hidden")
    if(this.lndAdminFirstNameInputTarget.value == null || this.lndAdminFirstNameInputTarget.value == "") {
      this.lndInviteAdminValidationErrorMessageTarget.innerHTML = "Name should not be blank"
    } else if(this.lndAdminEmailInputTarget.value == null || this.lndAdminEmailInputTarget.value == "") {
      this.lndInviteAdminValidationErrorMessageTarget.innerHTML = "Email should not be blank"
    } else if(this.lndAdminMobileNumberInputTarget.value == null || this.lndAdminMobileNumberInputTarget.value == "") {
      this.lndInviteAdminValidationErrorMessageTarget.innerHTML = "Mobile number should not be blank"
    } else if(this.callingCodeInputTarget.value == null || this.callingCodeInputTarget.value == "") {
      this.lndInviteAdminValidationErrorMessageTarget.innerHTML = "Calling code should not be blank"
    } else if(this.selectedRoleIds.length == 0) {
      this.lndInviteAdminValidationErrorMessageTarget.innerHTML = "Please select atleast one role"
    }
  }

  showConfirmLoadingSymbol() {
    $("#lnd-admin-invite-confirmation-loading-symbol").removeClass("hidden");
    this.confirmButtonTarget.classList.add("hidden")
    this.lndInviteAdminValidationErrorsContainerTarget.classList.add("hidden")
  }

  hideConfirmLoadingSymbol() {
    $("#lnd-admin-invite-confirmation-loading-symbol").addClass("hidden");
    this.confirmButtonTarget.classList.remove("hidden")
  }

  hidelndInviteAdminValidationErrorsContainer() {
    this.lndInviteAdminValidationErrorsContainerTarget.classList.add("hidden")
  }

  // Roles
  selectRole({ params }) {
    this.selectedRolesOuterContainerTarget.classList.remove("hidden")
    let selectedRoleId = params.selectedRoleId
    this.selectedRoleIds.push(selectedRoleId)

    var selectedButtons = $(`button[id='lnd-admin-invite-role-btn'][data-selected-role-id='${selectedRoleId}']`)
    for (const selectedButton of selectedButtons) {
      selectedButton.classList.add("hidden")
    }

    this._createAndAddSelectedRoleElementsInContainer({"id": selectedRoleId, "name": this._getStringAfterRemovingExtraSpace(selectedButtons[0].innerText)})
  }

  removeSelectedRole({ params }) {
    let selectedRoleId = params.selectedRoleId
    this._removeSelectedRoleFromListAndSelectedRoleIDs(selectedRoleId)
  }

  // Teams
  allTeams() {
    var alreadyLoadedTeamIds = []
    $(`button[id='lnd-admin-invite-team-btn']`).each(function( index, element ) {
      alreadyLoadedTeamIds.push(element.dataset.selectedTeamId)
    });

    this._showMoreTeamsLoading()
    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/admins/all-teams',
      data: {
        already_loaded_team_ids: alreadyLoadedTeamIds
      },
      success: function(response) {
        this._hideMoreItemsLoadingSymbol()
        this.moreTeamsContainerTarget.innerHTML = JSON.parse(response)
      }.bind(this),
      error: function(xhr, error){
        this.moreTeamsContainerTarget.classList.add("hidden")
        console.log(error)
      }.bind(this)
    })
  }

  selectTeam({ params }) {
    this._updateSelectedTeamBtnStateAndPopulateArray(params.selectedTeamId, "selectTeamButton")
  }

  // Batches

  allBatches({ params }) {
    var alreadyLoadedBatchIds = []
    $(`button[id='lnd-admin-invite-batch-btn']`).each(function( index, element ) {
      alreadyLoadedBatchIds.push(element.dataset.selectedBatchId)
    });

    this.showMoreBatchesLoading()
    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/admins/all-batches',
      data: {
        already_loaded_batch_ids: alreadyLoadedBatchIds
      },
      success: function(response) {
        this.hideLoadingSymbol()
        this.moreBatchesContainerTarget.innerHTML = JSON.parse(response)
        this.firstFiveBatchesContainerTarget.classList.toggle("hidden")
      }.bind(this),
      error: function(xhr, error){
        this.moreBatchesContainerTarget.classList.add("hidden")
        this.firstFiveBatchesContainerTarget.classList.remove("hidden")
        console.log(error)
      }.bind(this)
    })
  }

  selectBatch({ params }) {
    this._updateSelectedBatchBtnStateAndPopulateArray(params.selectedBatchId, "selectBatchButton")
  }

  // Private methods

  _createAndAddSelectedRoleElementsInContainer(roleData) {
    var selectedRoleContainer = document.createElement("div");
    selectedRoleContainer.classList.add("btn", "border", "border-gray-200", "bg-gray-50", "text-gray-600");
    selectedRoleContainer.setAttribute("id", `selected_role_button_id_${roleData.id}`);
    selectedRoleContainer.dataset.action = 'click->lnd--user-management--admins--invite#removeSelectedRole'
    selectedRoleContainer.setAttribute("data-lnd--user-management--admins--invite-selected-role-id-param", `${roleData.id}`)
    selectedRoleContainer.innerHTML = `<span class="fa fa-check -ml-0.5 mr-2 h-4 w-4 text-primary-500"></span> ${roleData.name}`

    var removeBtn = document.createElement("button");
    removeBtn.classList.add("ml-2", "text-gray-600", "flex", "items-center", "focus:outline-none")
    removeBtn.innerHTML = '<i class="fa fa-times mt-0.5" aria-hidden="true"></i>'
    removeBtn.dataset.action = 'click->lnd--user-management--admins--invite#removeSelectedRole'
    removeBtn.setAttribute("data-lnd--user-management--admins--invite-selected-role-id-param", `${roleData.id}`)
    selectedRoleContainer.appendChild(removeBtn)
    this.selectedRoleButtonsContainerTarget.appendChild(selectedRoleContainer);
  }

  _addRoleBackToList(selectedRoleId) {
    this.roleButtonTargets.forEach((target, index) => {
      let roleId = JSON.parse(target.dataset.selectedRoleId)
      if(roleId == selectedRoleId) {
        target.classList.remove("hidden")
      }
    })
  }

  _isAnyFieldInvalid() {
    return this._areAllPersonalDetailsAdded() == false ||
           this.selectedRoleIds.length == 0 ||
           this.selectedLndTeamIds.length == 0
  }

  _areAllPersonalDetailsAdded() {
    return (this.lndAdminFirstNameInputTarget.value != null && this.lndAdminFirstNameInputTarget.value != "") &&
           (this.lndAdminLastNameInputTarget.value != null && this.lndAdminLastNameInputTarget.value != "") &&
           (this.lndAdminEmailInputTarget.value != null && this.lndAdminEmailInputTarget.value != "") &&
           (this.lndAdminMobileNumberInputTarget.value != null && this.lndAdminMobileNumberInputTarget.value != "")
  }

  _removeSelectedRolesOnCloseModal() {
    this.roleButtonTargets.forEach((target, index) => {
      let roleId = JSON.parse(target.dataset.selectedRoleId)
      if(this.selectedRoleIds.includes(roleId)) {
        this._removeSelectedRoleFromListAndSelectedRoleIDs(roleId)
      }
    })
  }

  _removeSelectedRoleFromListAndSelectedRoleIDs(selectedRoleId) {
    var index = this.selectedRoleIds.indexOf(selectedRoleId)
    this.selectedRoleIds.splice(index, 1)
    this._addRoleBackToList(selectedRoleId)
    var roleButton = document.getElementById("selected_role_button_id_"+selectedRoleId)
    roleButton.parentNode.removeChild(roleButton);
    if(this.selectedRoleIds.length == 0) {
      this.selectedRolesOuterContainerTarget.classList.add("hidden")
    }
  }

  _clearForm() {
    this.lndAdminEmailInputTarget.value = ""
    this.lndAdminFirstNameInputTarget.value = ""
    this.lndAdminLastNameInputTarget.value = ""
    this.lndAdminEmployeeIdInputTarget.value = ""
    this.callingCodeInputTarget[0].selected = true
    this.lndAdminMobileNumberInputTarget.value = ""
    this.jobDesignationInputTarget[0].selected = true
    this.baseLocationInputTarget[0].selected = true
    this.lndAdminJoiningDateInputTarget.value = ""
    this.assignTeamInputTarget.value = ""
    this.assignBatchInputTarget.value = ""
    this._removeSelectedRolesOnCloseModal()
    this._clearSelectedTeams()
    this._clearSelectedBatches()
    this._removeTagifyTags()
    this._hideMoreTeams()
    this._hideMoreBatches()

    this.selectedLndTeamIds = []
    this.selectedLndBatchIds = []
  }

  _setPersonalDetailsTabSelected() {
    $("#lnd-admin-roles-tab").removeClass("active")
    $("#lnd-admin-personal-details-tab").addClass("active")
    $("#adminRoles").removeClass("active")
    $("#adminRoles").removeClass("show")
    $("#adminPersonalDetails").addClass("active")
    $("#adminPersonalDetails").addClass("show")
  }

  _enableTagifyForTextfields() {
    this.tagifyForTeam = new Tagify(this.assignTeamInputTarget, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value)
    })
    this.tagifyForTeam.on('remove', this._removeTeamTag.bind(this))
    this.tagifyForTeam.on('focus', this._suggestionForLndTeams.bind(this))
    this.tagifyForTeam.on('dropdown:select', this._dropdownSelectedTeam.bind(this))

    this.tagifyForBatch = new Tagify(this.assignBatchInputTarget, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value)
    })
    this.tagifyForBatch.on('remove', this._removeBatchTag.bind(this))
    this.tagifyForBatch.on('focus', this._suggestionForLndBatches.bind(this))
    this.tagifyForBatch.on('dropdown:select', this._dropdownSelectedBatch.bind(this))
  }

  _removeTeamTag(e) {
    var data = e.detail.data
    if(this.selectedLndTeamIds.includes(data.teamId)) {
      var index = this.selectedLndTeamIds.indexOf(data.teamId)
      this.selectedLndTeamIds.splice(index, 1)
      this._addTeamBackToList(data.teamId)
    }

    this._getCourses()
  }

  _addTeamBackToList(selectedLndTeamId) {
    var selectedButtons = $(`button[id='lnd-admin-invite-team-btn'][data-selected-team-id='${selectedLndTeamId}']`)
    for (const selectedButton of selectedButtons) {
      selectedButton.classList.remove("hidden")
    }
  }

  _suggestionForLndTeams(e) {
    var value = e.detail.value
    this.tagifyForTeam.whitelist = null // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    this.tagifyForTeam.loading(true).dropdown.hide()

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/lnd-teams-suggestions',
      data: {
        query: value
       },
      success: function(response) {
        console.log(response);
        var lndTeams = []
        response.lndTeams.forEach((lndTeam) => {
          lndTeams.push({"value": lndTeam.name, "teamId": lndTeam.id})
        })
        this.tagifyForTeam.whitelist = lndTeams
        this.tagifyForTeam.loading(false).dropdown.show(value)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.tagifyForTeam.loading(false).dropdown.hide()
      }.bind(this)
    })
  }

  _dropdownSelectedTeam(e) {
    var data = e.detail.data
    console.log(data)
    this._updateSelectedTeamBtnStateAndPopulateArray(data.teamId, "dropdown")
  }

  _updateSelectedTeamBtnStateAndPopulateArray(selectedTeamId, selectionType) {
    this.selectedLndTeamIds.push(selectedTeamId)
    var selectedButtons = $(`button[id='lnd-admin-invite-team-btn'][data-selected-team-id='${selectedTeamId}']`)

    for (const selectedButton of selectedButtons) {
      selectedButton.classList.add("hidden")
    }

    if(selectionType != "dropdown") {
      var tags = []
      tags.push({"value": this._getStringAfterRemovingExtraSpace(selectedButtons[0].innerText), "teamId": selectedTeamId});
      this.tagifyForTeam.addTags(tags);
    }

    this._getCourses()
  }

  _getStringAfterRemovingExtraSpace(textToRemoveSpace){
    return textToRemoveSpace.replace(/\s+/g, ' ').trim()
  }

  _showMoreTeamsLoading() {
    this.moreTeamsBtnTarget.classList.add("hidden")
    this.moreTeamsContainerTarget.classList.remove("hidden")
    this._showMoreItemsLoadingSymbol()
  }

  _showMoreItemsLoadingSymbol() {
    this.loadingContainerTarget.classList.remove('hidden')
  }

  _hideMoreItemsLoadingSymbol() {
    this.loadingContainerTarget.classList.add('hidden')
  }

  _removeBatchTag(e) {
    var data = e.detail.data
    if(this.selectedLndBatchIds.includes(data.batchId)) {
      var index = this.selectedLndBatchIds.indexOf(data.batchId)
      this.selectedLndBatchIds.splice(index, 1)
      this._addBatchBackToList(data.batchId)
    }
    this._getCourses()
  }

  _clearSelectedBatches() {
    this.selectedLndBatchIds.forEach((lndBatchId) => {
      this._addBatchBackToList(lndBatchId)
      this.tagifyForBatch.removeTags(this.tagifyForBatch.value.find(tag => { return tag.batchId == lndBatchId }).value)
    })

    this.tagifyForBatch = null
  }

  _suggestionForLndBatches(e) {
    var value = e.detail.value
    this.tagifyForBatch.whitelist = null // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    this.tagifyForBatch.loading(true).dropdown.hide()

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employees/lnd-batches-suggestions',
      data: {
        query: value
       },
      success: function(response) {
        console.log(response);
        var lndBatches = []
        response.lndBatches.forEach((lndBatch) => {
          lndBatches.push({"value": lndBatch.name, "batchId": lndBatch.id})
        })
        this.tagifyForBatch.whitelist = lndBatches
        this.tagifyForBatch.loading(false).dropdown.show(value)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.lndBatches.loading(false).dropdown.hide()
      }.bind(this)
    })
  }

  _dropdownSelectedBatch(e) {
    var data = e.detail.data
    this._updateSelectedBatchBtnStateAndPopulateArray(data.batchId, "dropdown")
  }

  _updateSelectedBatchBtnStateAndPopulateArray(selectedBatchId, selectionType) {
    this.selectedLndBatchIds.push(selectedBatchId)
    var selectedButtons = $(`button[id='lnd-admin-invite-batch-btn'][data-selected-batch-id='${selectedBatchId}']`)

    for (const selectedButton of selectedButtons) {
      selectedButton.classList.add("hidden")
    }

    if(selectionType != "dropdown") {
      var tags = []
      tags.push({"value": this._getStringAfterRemovingExtraSpace(selectedButtons[0].innerText), "batchId": selectedBatchId});
      this.tagifyForBatch.addTags(tags);
    }

    this._getCourses()
  }

  _addBatchBackToList(selectedLndBatchId) {
    var selectedButtons = $(`button[id='lnd-admin-invite-batch-btn'][data-selected-batch-id='${selectedLndBatchId}']`)
    for (const selectedButton of selectedButtons) {
      selectedButton.classList.remove("hidden")
    }
  }

  _clearSelectedTeams() {
    this.selectedLndTeamIds.forEach((lndTeamId) => {
      this._addTeamBackToList(lndTeamId)
      this.tagifyForTeam.removeTags(this.tagifyForTeam.value.find(tag => { return tag.teamId == lndTeamId }).value)
    })
    this.tagifyForTeam = null
  }

  _clearSelectedBatches() {
    this.selectedLndBatchIds.forEach((lndBatchId) => {
      this._addBatchBackToList(lndBatchId)
      this.tagifyForBatch.removeTags(this.tagifyForBatch.value.find(tag => { return tag.batchId == lndBatchId }).value)
    })

    this.tagifyForBatch = null
  }

  _hideMoreTeams() {
    if(this.hasMoreTeamsBtnTarget){
      this.moreTeamsBtnTarget.classList.remove("hidden")
      this.moreTeamsContainerTarget.classList.add("hidden")
      this.moreTeamsContainerTarget.innerHTML = ""
      this.firstFiveTeamsContainerTarget.classList.remove("hidden")
    }
  }

  _hideMoreBatches() {
    if(this.hasMoreBatchesBtnTarget){
      this.moreBatchesBtnTarget.classList.remove("hidden")
      this.moreBatchesContainerTarget.classList.add("hidden")
      this.moreBatchesContainerTarget.innerHTML = ""
      this.firstFiveBatchesContainerTarget.classList.remove("hidden")
    }
  }

  _removeTagifyTags() {
    document.querySelectorAll(".admin_invite_assign_team_container .tagify").forEach(
      function(tag, index, array) {
        tag.remove()
      }
    )

    document.querySelectorAll(".admin_invite_assign_batch_container .tagify").forEach(
      function(tag, index, array) {
        tag.remove()
      }
    )
  }

  _getCourses() {
    this.confirmButtonTarget.classList.add("disable")

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/employee/auto-enrollment-courses',
      data: {
        team_ids: this.selectedLndTeamIds,
        batch_ids: this.selectedLndBatchIds
      },
      success: function(response) {
        $("#admin-invite-assigned-courses").removeClass("hidden")
        $("#admin-invite-assigned-courses").html(JSON.parse(response))
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.lndInviteAdminValidationErrorsContainerTarget.classList.remove("hidden")
        this.lndInviteAdminValidationErrorMessageTarget.classList.remove("hidden")
        this.lndInviteAdminValidationErrorMessageTarget.innerHTML = xhr.responseJSON.error_message
      }.bind(this)
    })
  }
}
