import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = [
    "teamNameInput",
    "parentTeamNameInput",
    "teamManagerInput",
    "teamManagerIds",
    "form",
    "createTeamBtn",
    "errorMessage"
  ]

  initialize() {}

  connect() {
    this.selectedTeamManagerIds = []
  }

  openCreateTeamModal(event){
    $("#lnd--team-modal").removeClass("hidden")
    $("#lnd--team-modal").css("display", "flex")

    this._enableTagifyForTeamManager()
    console.log(event.currentTarget.dataset);
    var event = "TLND_TEAMS_CREATE_NEW_TEAM_CLICKED"
    var btnName = "Create new team"
    if(this.data.get('btnSrc') == "teams_add_subteam_btn") {
      event = "TLND_TEAMS_ADD_SUBTEAM_CLICKED"
      btnName = "Add sub team"
    }
    TmoEvents.logAsync(event, { app_user_id: this.data.get('appUserId'), btn_src: this.data.get('btnSrc'), btn_name: btnName })
  }

  closeCreateTeamModal() {
    $("#lnd--team-modal").addClass("hidden")
    $("#lnd--team-modal").css("display", "none")
    this._hideErrorMessage()
    this._disableConfirmBtn()
    this._clearForm()
  }

  confirmButtonClicked({ params }) {
    var event = "TLND_TEAMS_CREATE_NEW_TEAM_CONFIRMED"
    if(this.data.get('confirmBtnSrc') == "teams_add_subteam_confirmed_btn") {
      event = "TLND_TEAMS_ADD_SUBTEAM_CONFIRMED"
    }
    TmoEvents.logAsync(event, { app_user_id: this.data.get('appUserId'), btn_src: this.data.get("confirmBtnSrc"), btn_name: "Confirm" })
    this.teamManagerIdsTarget.value = this.selectedTeamManagerIds
    Rails.fire(this.formTarget, 'submit')
  }

  toggleConfirmButtonVisibility() {
    if(this._isTeamNameEmpty() == true) {
      this._disableConfirmBtn()
      this._showErrorMessage("Please enter valid team name")
    } else {
      this._enableConfirmBtn()
      this._hideErrorMessage()
    }
  }

  _isTeamNameEmpty() {
    return this.teamNameInputTarget.value == null || this.teamNameInputTarget.value == ""
  }

  _enableTagifyForTeamManager() {
    this.tagifyForTeamManager = new Tagify(this.teamManagerInputTarget, {
      enforceWhitelist: true,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value),
      templates: {
        dropdownItemNoMatch: function(data) {
          return `<div class='${this.settings.classNames.dropdownItem}' value="noMatch" tabindex="0" role="option">
              No suggestion found for: <strong>${data.value}</strong>
          </div>`
        }
      }
    })
    this.tagifyForTeamManager.on('remove', this._removeTeamManagerTag.bind(this))
    this.tagifyForTeamManager.on('focus', this._suggestionForTeamManagers.bind(this))
    this.tagifyForTeamManager.on('dropdown:select', this._dropdownSelectedTeamManager.bind(this))
  }

  _showErrorMessage(errorMessage) {
    this.errorMessageTarget.classList.remove("hidden")
    this.errorMessageTarget.innerHTML = errorMessage
  }

  _hideErrorMessage() {
    this.errorMessageTarget.classList.add("hidden")
  }

  _enableConfirmBtn() {
    this.createTeamBtnTarget.classList.remove("bg-gray-300", "text-white", "cursor-not-allowed", "pointer-events-none")
    this.createTeamBtnTarget.classList.add("btn-primary", "cursor-pointer")
    this.createTeamBtnTarget.removeAttribute("disabled")
  }

  _disableConfirmBtn() {
    this.createTeamBtnTarget.classList.add("bg-gray-300", "text-white", "cursor-not-allowed", "pointer-events-none")
    this.createTeamBtnTarget.classList.remove("btn-primary", "cursor-pointer")
    this.createTeamBtnTarget.setAttribute("disabled", true)
  }

  _clearForm() {
    this.teamNameInputTarget.value = ""
    this.parentTeamNameInputTarget.value = ""
    this.teamManagerInputTarget.value = ""
    this._clearSelectedTeamManagers()
    this._removeTagifyTags()
  }

  _clearSelectedTeamManagers() {
    this.selectedTeamManagerIds.forEach((teamManagerId) => {
      this.tagifyForTeamManager.removeTags(this.tagifyForTeamManager.value.find(tag => { return tag.teamManagerId == teamManagerId }).value)
    })
    this.tagifyForTeamManager = null
  }

  _removeTeamManagerTag(e) {
    var data = e.detail.data
    if(this.selectedTeamManagerIds.includes(data.teamManagerId)) {
      var index = this.selectedTeamManagerIds.indexOf(data.teamManagerId)
      this.selectedTeamManagerIds.splice(index, 1)
    }
  }

  _removeTagifyTags() {
    document.querySelectorAll(".assign_team_manager_container .tagify").forEach(
      function(tag, index, array) {
        tag.remove()
      }
    )
  }

  _suggestionForTeamManagers(e) {
    var value = e.detail.value
    this.tagifyForTeamManager.whitelist = null // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    this.tagifyForTeamManager.loading(true).dropdown.hide()

    $.ajax({
      type: 'get',
      url: '/api/web/admin/user_management/team/managers',
      data: {
        query: value
       },
      success: function(response) {
        console.log(response);
        var lndTeamManagers = []
        response.lndTeamManagers.forEach((lndTeamManager) => {
          lndTeamManagers.push({"value": lndTeamManager.fullName, "teamManagerId": lndTeamManager.id})
        })
        this.tagifyForTeamManager.whitelist = lndTeamManagers
        this.tagifyForTeamManager.loading(false).dropdown.show(value)
      }.bind(this),
      error: function(xhr, error){
        console.log(error)
        this.tagifyForTeamManager.loading(false).dropdown.hide()
      }.bind(this)
    })
  }

  _dropdownSelectedTeamManager(e) {
    var data = e.detail.data
    console.log(data)
    var selectedTeamManagerId = data.teamManagerId
    this.selectedTeamManagerIds.push(selectedTeamManagerId)
  }
}
