import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "form", "previewLoadingIndicator",  "setupLoadingMessage" ]

  initialize(){}

  connect(){
    $(this.element).find('input[type=color]').change(() => this._save())
    $('#preview').on('load', function(){ this._client_site_preview_loded() }.bind(this) )
    console.log('connected')
    this.startSetupLoading()
  }

  disconnect(){}

  startSetupLoading(){
    setTimeout(() => this._set_loading_message("Installing theme"), 2000)
    setTimeout(() => this._set_loading_message("Providing finishing touches"), 4000)
    setTimeout(() => this._set_loading_message("Almost done!"), 4800)
  }

  updatedSuccessfully(){
    console.log("Colors updated")
  }

  error(){
    console.log("Failed to update")
  }

  _set_loading_message(msg){
    document.getElementById('initialLoadingMessage').innerHTML = msg
  }

  _save(){
    Rails.fire(this.formTarget, 'submit')
    this._sendMessageToPreviewIframe()
  }

  _sendMessageToPreviewIframe(){
    var message = {
      action: '_updatePrimaryAndSecondaryColor',
      newColor: {
        primaryColor: document.getElementById('primary_color').value,
        secondaryColor: document.getElementById('secondary_color').value
      }
    }
    document.getElementById('preview').contentWindow.postMessage(message, '*');
  }

  _client_site_preview_loded(){
    document.getElementById('initialLoadingMessageContainer').style.display = 'none';
    document.getElementById('favicon_preview').style.display = null;
    document.getElementById('preview_loading_indicator').style.display = 'none';
  }
}
