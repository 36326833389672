import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'canceledBasePrice',
    'basePrice',
    'tax',
    'amountDue',

    'nextPaymentAttemptDateContainer',

    'showCouponFormButton',

    'couponForm',
    'couponTextBox',
    'hideCouponFormButton',
    'applyCouponButton',

    'couponErrorMessageSection',
    'couponErrorMessageText',

    'couponAppliedMessageSection',
    'couponAppliedMessageHeading',
    'couponAppliedMessageText',
    'removeCouponLink',

    'promotionCodeIdHiddenField'
  ]

  initialize() {
    console.log("settings--advanced--plan--switch-confirm controller initialize")
  }

  connect() {
    console.log("settings--advanced--plan--switch-confirm controller connected");

    this.initDetails = {
      amountDueValue: this.amountDueTarget.innerHTML,
      nextPaymentAttemptDateContainerValue: this.nextPaymentAttemptDateContainerTarget.innerHTML,
      upcomingInvoice: document.getElementById('upcoming_invoice').innerHTML
    }

    if(this.hasBasePriceTarget == true){
      this.initDetails.BasePrice = this.basePriceTarget.innerHTML
    }

    if(this.hasTaxTarget == true ){
      this.initDetails.tax = this.taxTarget.innerHTML
    }

    this.preApplyCouponCode = this.data.get('preApplyCouponCode')

    if(this.preApplyCouponCode != undefined && this.preApplyCouponCode != null && this.preApplyCouponCode != ''){
      this._preApplyCoupon()
    }
  }

  disconnect(){
    console.log("settings--advanced--plan--switch-confirm controller disconnect")
  }

  showCouponForm(){
    this.couponFormTarget.classList.remove('hidden')
    this.showCouponFormButtonTarget.classList.add('hidden')
  }

  hideCouponForm(){
    this.couponFormTarget.classList.add('hidden')
    this.couponErrorMessageSectionTarget.classList.add('hidden')
    this.showCouponFormButtonTarget.classList.remove('hidden')
  }

  applyCoupon(){
    this.applyCouponButtonTarget.innerHTML = "Applying..."
    this._applyCouponPreview().then(
      function(data, status, xhr){
        console.log("_applyCouponPreview -> data:")
        console.log(data)

        if(data.status == 'succeeded'){
          this._couponAppliedSuccessfully(data)
        } else {
          this._failedToApplyCoupon(data)
        }

        this.applyCouponButtonTarget.innerHTML = "Apply"
      }.bind(this),
      function(jqXHR, status, error) {
        this.applyCouponButtonTarget.innerHTML = "Apply"
        console.log(error);
      }.bind(this)
    )
  }

  removeCoupon(){
    document.getElementById('coupon_code_input_field').value = ""
    this.couponAppliedMessageSectionTarget.classList.add('hidden')
    this.promotionCodeIdHiddenFieldTarget.value = null
    this.amountDueTarget.innerHTML = this.initDetails.amountDueValue
    this.nextPaymentAttemptDateContainerTarget.innerHTML = this.initDetails.nextPaymentAttemptDateContainerValue
    this.showCouponFormButtonTarget.classList.remove('hidden')
    this.canceledBasePriceTarget.classList.add('hidden')

    if(this.hasBasePriceTarget == true){
      this.basePriceTarget.innerHTML = this.initDetails.BasePrice
    }

    if(this.hasTaxTarget == true ){
      this.taxTarget.innerHTML = this.initDetails.tax
    }

    document.getElementById('upcoming_invoice').innerHTML = this.initDetails.upcomingInvoice
  }

  _preApplyCoupon(){
    console.log("Pre applying coupon")
    this.showCouponForm()
    document.getElementById('coupon_code_input_field').value = this.preApplyCouponCode
    this.applyCoupon()
  }

  _applyCouponPreview(){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: "/api/web/admin/settings/advanced/plan/switch/apply-coupon-preview.json",
      method: "GET",
      data: {
        price_id: this.data.get('priceId'),
        promotion_code: document.getElementById('coupon_code_input_field').value
      },
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }

  _couponAppliedSuccessfully(data){
    this.canceledBasePriceTarget.classList.remove('hidden')
    this.canceledBasePriceTarget.innerHTML = data.subtotal_display_value

    if(this.hasBasePriceTarget == true){
      this.basePriceTarget.innerHTML = data.discounted_subtotal_display_value
    }

    if(this.hasTaxTarget == true ){
      this.taxTarget.innerHTML = data.tax_display_value
    }

    this.amountDueTarget.innerHTML = data.amount_due_display_value

    if(data.next_payment_attempt_date == undefined || data.next_payment_attempt_date == null || data.next_payment_attempt_date == ''){
      this.nextPaymentAttemptDateContainerTarget.classList.add('hidden')
    } else {
      this.nextPaymentAttemptDateContainerTarget.innerHTML = `on ${data.next_payment_attempt_date}`
      this.nextPaymentAttemptDateContainerTarget.classList.remove('hidden')
    }

    this.promotionCodeIdHiddenFieldTarget.value = data.promotion_code_id

    this.couponAppliedMessageHeadingTarget.innerHTML = `You get ${data.total_discount_amount_display_value} off`
    this.couponAppliedMessageTextTarget.innerHTML = `Coupon ${this.couponTextBoxTarget.value} applied successfully`
    this.couponAppliedMessageSectionTarget.classList.remove('hidden')

    this.couponFormTarget.classList.add('hidden')
    this.couponErrorMessageSectionTarget.classList.add('hidden')

    document.getElementById('upcoming_invoice').innerHTML = JSON.stringify(data.upcoming_invoice, null, 2)
  }

  _failedToApplyCoupon(data){
    this.couponErrorMessageTextTarget.innerHTML = data.message
    this.couponErrorMessageSectionTarget.classList.remove('hidden')
  }
}
