import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "selectCertificateModal",
    "previewCertificateModal",
    "selectCertificateModalCertificateThumbnail",
    "certificatesDesignIdHiddenField",
    "attachCertificatesDesignForm",
    "attachCertificatesDesignBtn",
    "issueCertificateManuallyModal",
    "courseUserRows",
    "loadingEnrolledUsersIndicator",
    "loadingEnrolledUsersMessage",
    "issueCertificateConfirmButton",
    "selectAllCourseUsersCheckbox",
    "searchTextField",
    "courseUsersTableContainer",
    "paginationButtonsContainer",
    "paginationPageButtonsContainer",
    "issueToAllSearchedAndFilteredCourseUserButton",
    "noCourseUserMessageContainer",
    "paginationNextPageButton",
    "paginationPreviousPageButton",
    "issueCertificateConfirmModal",
    "issueCertificateConfirmModalInitialContent",
    "issueCertificateConfirmModalProgressing",
    "issueCertificateConfirmModalProcessed",
    "issueCertificateConfirmFailedMessage",
    "removeCertificateConfirmModal",
    "selectCertificateModalFooterDisableButton",
    "attachCertificatesDesignForm"
  ]

  initialize(){
    this.originalTmMainContentHolderZIndex = document.getElementById('tmMainContentHolder').style.getPropertyValue('z-index')
  }

  connect(){
    this.courseId = this.data.get('courseId')
    this.attachedCertificatesDesignId = this.data.get('attachedCertificatesDesignId')
    this.selectedCertificatesDesignId = this.attachedCertificatesDesignId
    if(this.hasSearchTextFieldTarget){
      this.currentSearchQuery = this.searchTextFieldTarget.value
    } else {
      this.currentSearchQuery = null
    }
    this.duration = this.data.get("duration") || 1000
    this.timeout = null
    this.status = 'idle' // 'idle', 'loading_users', 'no_users'
    this.courseUsersResult = {
      totalEnrolledCourseUsers: 0,
      totalEntries: 0,
      totalPages: 0,
      previousPage: null,
      currentPage: null,
      nextPage: null,
      entries: []
    }

    this._loadCourseUsers(1)
  }

  openSelectCertificateModal(e){
    this.selectedCertificatesDesignId = this.attachedCertificatesDesignId

    // hack to not show topNav above/on the modal background
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
    this.selectCertificateModalTarget.style.display = "block"
    if(this.selectedCertificatesDesignId != undefined && this.selectedCertificatesDesignId != null && this.selectedCertificatesDesignId != ""){
      this.selectCertificateModalCertificateThumbnailTargets.forEach( certificateThumbnailTarget => {
        if(this.selectedCertificatesDesignId == certificateThumbnailTarget.dataset.certificatesDesignId){
          certificateThumbnailTarget.classList.add('border-primary-600', 'border-2')
          certificateThumbnailTarget.classList.remove('border-gray-200', 'border')
          certificateThumbnailTarget.scrollIntoView()
        } else {
          certificateThumbnailTarget.classList.add('border-gray-200', 'border')
          certificateThumbnailTarget.classList.remove('border-primary-600', 'border-2')
        }
      }, this)
      this.certificatesDesignIdHiddenFieldTarget.value = this.selectedCertificatesDesignId

      if(this.selectedCertificatesDesignId == this.attachedCertificatesDesignId){
        this.attachCertificatesDesignFormTarget.classList.add("hidden")
        this.selectCertificateModalFooterDisableButtonTarget.classList.remove('hidden')
        this.selectCertificateModalFooterDisableButtonTarget.innerHTML = "This certificate is already added"
      }
    } else {
      if(this.hasAttachCertificatesDesignFormTarget){
        this.attachCertificatesDesignFormTarget.classList.add("hidden")
        this.selectCertificateModalFooterDisableButtonTarget.classList.remove('hidden')
        this.selectCertificateModalFooterDisableButtonTarget.innerHTML = "Select certificate"
      }
    }
  }

  openPreviewCertificateModal(e){
    // hack to not show topNav above/on the modal background
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
    this.previewCertificateModalTarget.style.display = "block"
  }

  closeSelectCertificateModal(e){
    this.selectCertificateModalTarget.style.display = "none"
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.originalTmMainContentHolderZIndex)
  }

  closePreviewCertificateModal(e){
    this.previewCertificateModalTarget.style.display = "none"
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.originalTmMainContentHolderZIndex)
  }

  openRemoveCertificateConfirmModal(){
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
    this.removeCertificateConfirmModalTarget.style.display = "block"
  }

  closeRemoveCertificateConfirmModal(e){
    this.removeCertificateConfirmModalTarget.style.display = "none"
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.originalTmMainContentHolderZIndex)
  }

  selectCertificatesDesign(e){
    this.selectedCertificatesDesignId = e.target.dataset.certificatesDesignId
    this.selectCertificateModalCertificateThumbnailTargets.forEach( certificateThumbnailTarget => {
      if(this.selectedCertificatesDesignId == certificateThumbnailTarget.dataset.certificatesDesignId){
        certificateThumbnailTarget.classList.add('border-primary-600', 'border-2')
        certificateThumbnailTarget.classList.remove('border-gray-200', 'border')
        // if(scrollIntoView){themeListItemTarget.scrollIntoView()}
      } else {
        certificateThumbnailTarget.classList.add('border-gray-200', 'border')
        certificateThumbnailTarget.classList.remove('border-primary-600', 'border-2')
      }
    }, this)
    this.certificatesDesignIdHiddenFieldTarget.value = this.selectedCertificatesDesignId
    this.attachCertificatesDesignFormTarget.classList.remove("hidden")
    this.selectCertificateModalFooterDisableButtonTarget.classList.add('hidden')

    if(this.selectedCertificatesDesignId == this.attachedCertificatesDesignId){
      this.attachCertificatesDesignFormTarget.classList.add("hidden")
      this.selectCertificateModalFooterDisableButtonTarget.classList.remove('hidden')
      this.selectCertificateModalFooterDisableButtonTarget.innerHTML = "This certificate is added"
    }
  }

  openIssueCertificateManuallyModal(){
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", "auto")
    this.issueCertificateManuallyModalTarget.style.display = "block"
  }

  closeIssueCertificateManuallyModal(){
    this.issueCertificateManuallyModalTarget.style.display = "none"
    document.getElementById('tmMainContentHolder').style.setProperty("z-index", this.originalTmMainContentHolderZIndex)
  }

  openIssueCertificateConfirmModal(){
    this.issueCertificateManuallyModalTarget.style.display = "none"
    this.issueCertificateConfirmModalTarget.style.display = "block"
  }

  closeIssueCertificateConfirmModal(){
    this.issueCertificateConfirmModalTarget.style.display = "none"
    this.issueCertificateManuallyModalTarget.style.display = "block"
    this.issueCertificateConfirmModalInitialContentTarget.classList.remove('hidden')
    this.issueCertificateConfirmModalProgressingTarget.classList.add('hidden')
    this.issueCertificateConfirmModalProcessedTarget.classList.add('hidden')
    this.issueCertificateConfirmFailedMessageTarget.classList.add('hidden')
  }

  fetchCourseUserBySearchAndFilter(){
    console.log("fetchCourseUserBySearchAndFilter called")
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      console.log(`search_query: ${this.searchTextFieldTarget.value}`)
      console.log(`filters: ${this._selectedFilterValues()}`)
      this.courseUserRowsTarget.innerHTML = ""
      this._loadCourseUsersOnSearchOrFilter()
    }, this.duration)
  }

  goToPage(e){
    if(e.target.dataset.page != this.courseUsersResult.currentPage){
      this._loadCourseUsersOnPageChange(e.target.dataset.page)
    }
  }

  goToPreviousPage(){
    if(this.courseUsersResult.previousPage != null){
      this._loadCourseUsersOnPageChange(this.courseUsersResult.previousPage)
    }
  }

  goToNextPage(){
    if(this.courseUsersResult.nextPage != null){
      this._loadCourseUsersOnPageChange(this.courseUsersResult.nextPage)
    }
  }

  issueCertificateToCourseUser(e){
    var courseUserId = e.target.dataset.courseUserId
    document.getElementById(`issueCertificateButton_${courseUserId}`).classList.add('hidden')
    document.getElementById(`failedToIssuedLabel_${courseUserId}`).classList.add('hidden')
    document.getElementById(`issuingLabel_${courseUserId}`).classList.remove('hidden')

    this._issueCertificateManually('single_course_user', courseUserId).then(
      function(){
        document.getElementById(`issuingLabel_${courseUserId}`).classList.add('hidden')
        document.getElementById(`issuedLabel_${courseUserId}`).classList.remove('hidden')
      }.bind(this),
      function(error){
        console.log(error)
        document.getElementById(`issuingLabel_${courseUserId}`).classList.add('hidden')
        document.getElementById(`issueCertificateButton_${courseUserId}`).classList.remove('hidden')
        document.getElementById(`failedToIssuedLabel_${courseUserId}`).classList.remove('hidden')
      }.bind(this)
    )
  }

  issueCertificateToAllSearchedAndFilterCourseUsers(){
    this.issueCertificateConfirmModalInitialContentTarget.classList.add('hidden')
    this.issueCertificateConfirmModalProgressingTarget.classList.remove('hidden')
    this._issueCertificateManually('multiple_course_users').then(
      function(){
        this.issueCertificateConfirmModalProgressingTarget.classList.add('hidden')
        this.issueCertificateConfirmModalProcessedTarget.classList.remove('hidden')
      }.bind(this),
      function(error){
        console.log(error)
        this.issueCertificateConfirmModalProgressingTarget.classList.add('hidden')
        this.issueCertificateConfirmModalInitialContentTarget.classList.remove('hidden')
        this.issueCertificateConfirmFailedMessageTarget.classList.remove('hidden')
      }.bind(this)
    )
  }

  _issueCertificateManually(mode, courseUserId = null){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: `/api/web/admin/course_manager/${this.courseId}/certificates/issue-certificates-manually.json`,
      method: "POST",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      data:{ mode: mode, course_user_id: courseUserId, search_query: this.searchTextFieldTarget.value, filters: this._selectedFilterValues() },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }

  _loadCourseUsersOnSearchOrFilter(){
    this._updateManuallyIssueCertificateModal('loadingCourseUsersOnSearchOrFilter')
    this._loadCourseUsers(1)
  }

  _loadCourseUsersOnPageChange(page){
    this._updateManuallyIssueCertificateModal('loadingCourseUsersOnPageChange')
    this._loadCourseUsers(page)
  }

  _updateManuallyIssueCertificateModal(status){
    if(status == 'loadingCourseUsersOnSearchOrFilter'){
      this.courseUsersTableContainerTarget.classList.add('hidden')
      this.paginationButtonsContainerTarget.classList.add('hidden')
      this.issueToAllSearchedAndFilteredCourseUserButtonTarget.classList.add('hidden')
      this.noCourseUserMessageContainerTarget.classList.add('hidden')

      this.loadingEnrolledUsersIndicatorTarget.classList.remove('hidden')
      this.loadingEnrolledUsersMessageTarget.classList.remove('hidden')

    } else if(status == 'loadingCourseUsersOnPageChange'){
      this.courseUsersTableContainerTarget.classList.add('hidden')
      this.noCourseUserMessageContainerTarget.classList.add('hidden')
      this.loadingEnrolledUsersMessageTarget.classList.add('hidden')

      this.loadingEnrolledUsersIndicatorTarget.classList.remove('hidden')
      this.paginationButtonsContainerTarget.classList.remove('hidden')
      this.issueToAllSearchedAndFilteredCourseUserButtonTarget.classList.remove('hidden')
    }else {
      if(this.courseUsersResult.totalEntries > 0){
        this.loadingEnrolledUsersIndicatorTarget.classList.add('hidden')
        this.loadingEnrolledUsersMessageTarget.classList.add('hidden')

        this.noCourseUserMessageContainerTarget.classList.add('hidden')

        this.courseUsersTableContainerTarget.classList.remove('hidden')
        this.paginationButtonsContainerTarget.classList.remove('hidden')
        this.issueToAllSearchedAndFilteredCourseUserButtonTarget.classList.remove('hidden')

      } else {
        this.loadingEnrolledUsersIndicatorTarget.classList.add('hidden')
        this.loadingEnrolledUsersMessageTarget.classList.add('hidden')
        this.courseUsersTableContainerTarget.classList.add('hidden')
        this.paginationButtonsContainerTarget.classList.add('hidden')
        this.issueToAllSearchedAndFilteredCourseUserButtonTarget.classList.add('hidden')

        this.noCourseUserMessageContainerTarget.classList.remove('hidden')
      }
    }
  }

  _selectedFilterValues(){
    var filters = []
    $.each($('input[name="enrolled_course_user_filters[]"]:checked'), function(index, checkBoxElement) {
      filters.push(checkBoxElement.value)
    })

    return filters
  }

  _loadCourseUsers(pageNo){
    this._fetchCourseUsers(pageNo).then(
      function(data, status, xhr){
        this.courseUsersResult = data
        this.courseUsersTableContainerTarget.innerHTML = eval(this.courseUsersResult.entries)
        this._updateCourseUserPaginationButtons()
        this._updateManuallyIssueCertificateModal('idle')
        if(this.courseUsersResult.totalEntries > 1){
          this.issueToAllSearchedAndFilteredCourseUserButtonTarget.classList.remove('hidden')
          this.issueToAllSearchedAndFilteredCourseUserButtonTarget.innerHTML = `Issue to all ${this.courseUsersResult.totalEntries} learners`
        } else {
          this.issueToAllSearchedAndFilteredCourseUserButtonTarget.classList.add('hidden')
        }
      }.bind(this),
      function(jqXHR, status, error){
        console.log(error)
      }.bind(this)
    )
  }

  _fetchCourseUsers(pageNo){
    var deferred = $.Deferred();
    var promise = deferred.promise();

    $.ajax({
      url: `/api/web/admin/course_manager/${this.courseId}/certificates/enrolled-course-users?page=${pageNo}`,
      method: "GET",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      data:{ search_query: this.searchTextFieldTarget.value, filters: this._selectedFilterValues() },
      success: function(data, status, xhr) {
        deferred.resolve(data, status, xhr);
      },
      error: function(jqXHR, status, error) {
        deferred.reject(jqXHR, status, error);
      }
    });

    return promise;
  }

  _updateCourseUserPaginationButtons(){
    var currentPage = this.courseUsersResult.currentPage
    var totalPages = this.courseUsersResult.totalPages

    if(totalPages == 0){
      return
    }

    var pagesButtons = []

    var disableButtonClasses = ["px-3", "h-8", "flex", "items-center", "justify-center", "border", "border-gray-200", "rounded", "text-white", "bg-gray-200", "cursor-not-allowed"]
    var activeButtonClasses =  ["px-3", "h-8", "flex", "items-center", "justify-center", "bg-primary-100", "border", "rounded", "border-primary-600", "text-primary-600", "hover:bg-primary-200", "hover:text-primary-700", "hover:border-primary-400", "cursor-pointer"]
    var neutralButtonClasses = ["px-3", "h-8", "flex", "items-center", "justify-center", "bg-white", "border", "border-gray-300", "rounded", "text-gray-500", "hover:bg-gray-100", "hover:text-gray-700", "hover:border-gray-400", "cursor-pointer"]


    if(totalPages <= 7){
      for (let i = 1; i <= totalPages; i++){
        pagesButtons.push({page: i, active: (i == currentPage)})
      }
    } else {
      pagesButtons.push({ page: 1, active: (currentPage == 1) })
      pagesButtons.push({ page: 2, active: (currentPage == 2) })

      if(currentPage == 3 || currentPage == 4) {
        pagesButtons.push({ page: 3, active: (currentPage == 3) })
      }
      if(currentPage == 4) {
        pagesButtons.push({ page: 4, active: (currentPage == 4) })
      }

      pagesButtons.push({ page: null, active: false })

      if(currentPage >= 5 && currentPage < totalPages - 3 ){
        pagesButtons.push({ page: currentPage, active: true})
      }

      if( currentPage >= 5 && totalPages - currentPage >= 4){
        pagesButtons.push({ page: null, active: false })

      }

      if(totalPages - currentPage == 3){
        pagesButtons.push({ page: totalPages - 3, active:  totalPages - 3 == currentPage })
      }

      if(totalPages - currentPage == 3 || totalPages - currentPage == 2 ){
        pagesButtons.push({ page: totalPages - 2, active: totalPages - 2 == currentPage })
      }

      pagesButtons.push({ page: totalPages - 1 , active: (currentPage ==  totalPages - 1) })
      pagesButtons.push({ page: totalPages, active: (currentPage == totalPages) })

    }

    this.paginationPreviousPageButtonTarget.className = ""
    if(this.courseUsersResult.previousPage == null){
      this.paginationPreviousPageButtonTarget.classList.add(...disableButtonClasses)
    } else {
      this.paginationPreviousPageButtonTarget.classList.add(...neutralButtonClasses)
    }

    this.paginationNextPageButtonTarget.className = ""
    if(this.courseUsersResult.nextPage == null){
      this.paginationNextPageButtonTarget.classList.add(...disableButtonClasses)
    } else {
      this.paginationNextPageButtonTarget.classList.add(...neutralButtonClasses)
    }

    this.paginationPageButtonsContainerTarget.innerHTML=""

    pagesButtons.forEach( pageButton => {
      var pageButtonElement = document.createElement('div')
      pageButtonElement.dataset.target = 'content--course-manager--certificates.paginationPageButton'

      if(pageButton.page != null){
        pageButtonElement.dataset.page = pageButton.page
        pageButtonElement.dataset.action = 'click->content--course-manager--certificates#goToPage'
        pageButtonElement.innerHTML = `<span class='pointer-events-none'>${pageButton.page}</span>`
      } else {
        pageButtonElement.innerHTML = `<span class='pointer-events-none'>...</span>`
      }

      if(pageButton.active){
        pageButtonElement.classList.add(...activeButtonClasses)
      } else {
        pageButtonElement.classList.add(...neutralButtonClasses)
      }

      this.paginationPageButtonsContainerTarget.appendChild(pageButtonElement)
    })
  }
}
