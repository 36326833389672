import { Controller } from 'stimulus'
import { TmoEvents } from 'shared/tmo_events'

export default class extends Controller {
  static targets = []

  initialize() {}

  connect() {}

  openManageEmployeesModal(event){
    $("#lnd-shared-manage-employees-modal").removeClass("hidden")
    $("#lnd-shared-manage-employees-modal").css("display", "flex")
    console.log(event.currentTarget.dataset);

    TmoEvents.logAsync("TLND_BATCHES_MANAGE_EMPLOYEES_CLICKED", { app_user_id: this.data.get('appUserId'), btn_src: "batches_manage_employees_btn", btn_name: "Manage employees" })
  }

  closeManageEmployeesModal() {
    $("#lnd-shared-manage-employees-modal").addClass("hidden")
    $("#lnd-shared-manage-employees-modal").css("display", "none")
  }
}
