import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { FbGtmTracker } from 'shared/fb_gtm_tracker'

export default class extends Controller {
  static targets = [
    "sourceCountryProperties",
    "fullName",
    "emailDiv",
    "email",
    "mobileNumberDiv",
    "mobileNumber",
    "passwordDiv",
    "password",
    "callingCode",
    "countryHiddenField",
    "whatsappOptInDiv",
    "createAccountBtn",
    "softwareAgreementCheckbox",
    "mobileNumberAsterisks",
    "whatsappOptInCheckBox",
    "whatsappOptInCheckBoxLabel"
  ];

  initialize(){
    this.countryProperties = this.sourceCountryPropertiesTarget.value.split("|||");
    this.countryId = this.countryProperties[0];
    this.countryCallingCode = this.countryProperties[1];
    this.isCountryIndia = this.countryProperties[2];
  }

  connect() {
    this.toggleWhatsappOptInCheckBoxDisabledProperty()
    FbGtmTracker.trackOnboardingEvent('TMO_SIGNUP_PAGE_VIEWED', {});
  }

  disconnect(){}

  handleCountryChange(){
    this.countryProperties = this.sourceCountryPropertiesTarget.value.split("|||");
    this.countryId = this.countryProperties[0];
    this.countryCallingCode = this.countryProperties[1];
    this.isCountryIndia = this.countryProperties[2];

    // Set the selected country in the hidden field.
    this.countryHiddenFieldTarget.value = this.countryId
    this.callingCodeTarget.textContent = this.countryCallingCode

    this._updateCountryOnServer()
    this.toggleCreateAccountVisibility()
    this.toggleMobileNumberFields()
    this.toggleWhatsappOptInCheckBoxDisabledProperty()
  }

  _updateCountryOnServer() {
    $.ajax({
      url: "/api/setup/update-country" ,
      method: "PUT",
      data:{country_id: this.countryId},
      success: function(response){
        console.log(response)
      },
      error: function(xhr, error){
        console.log(error)
      }
    })
  }

  toggleCreateAccountVisibility() {
    if(this.fullNameTarget.value == "" ||
      (this.emailTarget.value == "") ||
      (this.mobileNumberTarget.value == "" && this.isCountryIndia == "true") ||
      (this.passwordTarget.value == "") ||
      !this.softwareAgreementCheckboxTarget.checked){
        this._disableCreateAccountButton()
    } else {
      this._enableCreateAccountButton()
    }
  }

  _disableCreateAccountButton() {
    this.createAccountBtnTarget.disabled = true
    this.createAccountBtnTarget.classList.add("cursor-not-allowed")
    this.createAccountBtnTarget.setAttribute("style", "background-color: #dbe2ea;");
    this.createAccountBtnTarget.classList.remove("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  _enableCreateAccountButton() {
    this.createAccountBtnTarget.disabled = false
    this.createAccountBtnTarget.classList.remove("cursor-not-allowed")
    this.createAccountBtnTarget.removeAttribute("style")
    this.createAccountBtnTarget.classList.add("cursor-pointer", "bg-primary-800", "hover:bg-primary-900")
  }

  toggleMobileNumberFields() {
    if(this.isCountryIndia == "true") {
      this.mobileNumberTarget.required = true
      this.mobileNumberAsterisksTarget.classList.remove("hidden")
    } else {
      this.mobileNumberTarget.required = false
      this.mobileNumberAsterisksTarget.classList.add("hidden")
    }
  }

  handleMobileNumberChange() {
    this.toggleWhatsappOptInCheckBoxDisabledProperty();
    this.toggleCreateAccountVisibility();
  }

  toggleWhatsappOptInCheckBoxDisabledProperty() {
    if((this.mobileNumberTarget.value == null || this.mobileNumberTarget.value == "") && this.countryId != "1") {
      this.whatsappOptInCheckBoxTarget.setAttribute("disabled", "disabled")
      this.whatsappOptInCheckBoxTarget.classList.remove("text-primary-600");
      this.whatsappOptInCheckBoxTarget.classList.add("text-gray-200");
      this.whatsappOptInCheckBoxLabelTarget.classList.add("text-gray-400");
    } else{
      this.whatsappOptInCheckBoxTarget.removeAttribute("disabled");
      this.whatsappOptInCheckBoxTarget.classList.add("text-primary-600");
      this.whatsappOptInCheckBoxTarget.classList.remove("text-gray-200");
      this.whatsappOptInCheckBoxLabelTarget.classList.remove("text-gray-400");

    }
  }

  trackFbGtmEventsForCreateAccountButtonClick() {
    FbGtmTracker.trackOnboardingEvent('TMO_CREATE_ACCOUNT_CLICKED', {});
  }
}
